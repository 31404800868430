export const branchList = [
    {
        branchNo: '१',
        branchName: 'थानकोट',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '२',
        branchName: 'बलम्बु',
        contactPerson: 'स्याम सुन्दर श्रेष्ठ',
        contactNo: '९८४९०७७७४७',
    },
    {
        branchNo: '३',
        branchName: 'किपुल्चा',
        contactPerson: 'मोहन महर्जन',
        contactNo: '९८६१६०८७०८',
    },
    {
        branchNo: '४',
        branchName: 'बालागाउं',
        contactPerson: 'मंगलकृष्ण महर्जन',
        contactNo: '९८४१३९७३६२',
    },
    {
        branchNo: '५',
        branchName: 'नगॉचा',
        contactPerson: 'हरिकृष्ण महर्जन',
        contactNo: '९८४१००३०२१',
    },
    {
        branchNo: '६',
        branchName: 'किर्तिपुर',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '७',
        branchName: 'थक्वा',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '८',
        branchName: 'तापख्य',
        contactPerson: 'दिल बहादुर श्रेष्ठ',
        contactNo: '९८४१७१८००८',
    },
    {
        branchNo: '९',
        branchName: 'सतुंगल',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '१०',
        branchName: 'ल्होखा',
        contactPerson: 'मन्दिरा श्रेष्ठ',
        contactNo: '९८४३७०५७१३',
    },
    {
        branchNo: '११',
        branchName: 'कृषि',
        contactPerson: 'राम भक्त श्रेष्ठ',
        contactNo: '९८४१३६४१६६',
    },
    {
        branchNo: '१२',
        branchName: 'बन्थोक',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '१३',
        branchName: 'गछें किपु',
        contactPerson: 'बुद्दिलाल ज्यापू',
        contactNo: '९८४१४५३३९३',
    },
    {
        branchNo: '१४',
        branchName: 'शोणितपुर',
        contactPerson: 'जुजुकाजी श्रेष्ठ',
        contactNo: '',
    },
    {
        branchNo: '१५',
        branchName: 'मिडिया',
        contactPerson: 'किशोर मानन्धर',
        contactNo: '९८४१२४००५५',
    },
    {
        branchNo: '१६',
        branchName: 'थक्वा मिसा पुच',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '१७',
        branchName: 'मिसा पुच बलम्बु',
        contactPerson: 'रचना तण्डुकार',
        contactNo: '',
    },
    {
        branchNo: '१८',
        branchName: 'मिसा पुच किपुल्चा',
        contactPerson: 'अष्टमाया महर्जन',
        contactNo: '',
    },
    {
        branchNo: '१९',
        branchName: 'मिसा पुच नगॉचा',
        contactPerson: 'हिराकुमारी महर्जन',
        contactNo: '९८४१८९५६७८',
    },
    {
        branchNo: '२०',
        branchName: 'ज्योतिष',
        contactPerson: 'केशर गोबिन्द जोशी',
        contactNo: '९८४१५३०३९३',
    },
    {
        branchNo: '२१',
        branchName: 'मिसा पुच बालागाउ',
        contactPerson: 'कमला बलामी',
        contactNo: '९८४१२३१४६६',
    },
    {
        branchNo: '२२',
        branchName: 'काठमाडौ',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '२३',
        branchName: 'ख्वप',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '२४',
        branchName: 'बर्मु टोल',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '२५',
        branchName: 'लुनिभा',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '२६',
        branchName: 'तीनथाना',
        contactPerson: 'ईन्द्र महर्जन',
        contactNo: '९८५१०३०७८२',
    },
    {
        branchNo: '२७',
        branchName: 'स्यनामी',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '२८',
        branchName: 'ज्याकमी',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '२९',
        branchName: 'थि थि बन्जा',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '३०',
        branchName: 'ल्हुती',
        contactPerson: 'मोहीनी बैध्य',
        contactNo: '९८०३१७२४३२',
    },
    {
        branchNo: '३१',
        branchName: 'टोखा',
        contactPerson: 'कृष्णहरी श्रेष्ठ',
        contactNo: '',
    },
    {
        branchNo: '३२',
        branchName: 'लायकू',
        contactPerson: 'प्रज्वल शाक्य',
        contactNo: '',
    },
    {
        branchNo: '३३',
        branchName: 'भस्तिपुर',
        contactPerson: 'बिक्रम तण्डुकार',
        contactNo: '९८४१५३६५२२',
    },
    {
        branchNo: '३४',
        branchName: 'हरसिद्दी',
        contactPerson: 'अक्कल दास महर्जन',
        contactNo: '९८४१३५०२४३',
    },
    {
        branchNo: '३५',
        branchName: 'सक्व',
        contactPerson: 'धर्म भक्त मल्ल',
        contactNo: '९८४१५६२५२६',
    },
    {
        branchNo: '३६',
        branchName: 'क्वन्लाछी टोखा',
        contactPerson: 'कृष्णहरी श्रेष्ठ',
        contactNo: '९८६०४९१६१०',
    },
    {
        branchNo: '३७',
        branchName: ' बेलायत कचा',
        contactPerson: 'शान्तबीर सिं तुलाधर',
        contactNo: '',
    },
    {
        branchNo: '३८',
        branchName: 'मिसा पुच टेखा',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '३९',
        branchName: 'लसकुस मिसा',
        contactPerson: 'निर्मला डंगोल',
        contactNo: '',
    },
    {
        branchNo: '४०',
        branchName: 'लुमन्ती मिसा पुच',
        contactPerson: 'थिके माया महर्जन',
        contactNo: '',
    },
    {
        branchNo: '४१',
        branchName: 'बल्खु',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '४२',
        branchName: 'तेष्टुंग',
        contactPerson: 'नरहरी गोपाली',
        contactNo: '९८४५०७०८६२',
    },
    {
        branchNo: '४३',
        branchName: 'हरसिद्दी मिसा',
        contactPerson: 'दशमाया महर्जन',
        contactNo: '९८१३८८८२०८',
    },
    {
        branchNo: '४४',
        branchName: 'शोणितपुर मिसा',
        contactPerson: 'कृष्णकुमारी श्रेष्ठ',
        contactNo: '९८४३६९३१६८',
    },
    {
        branchNo: '४५',
        branchName: 'सुर्यमह्र ख्वप',
        contactPerson: 'सुमन प्रजापती',
        contactNo: '९८६००७३६५२',
    },
    {
        branchNo: '४६',
        branchName: 'ईन्द्र बिनायक',
        contactPerson: 'हिरालाल नगरकोटी',
        contactNo: '९८४५५०७७६२',
    },
    {
        branchNo: '४७',
        branchName: 'पन्नु',
        contactPerson: 'निरन्जन श्रेष्ठ ',
        contactNo: '९८५५०७०८१०',
    },
    {
        branchNo: '४८',
        branchName: 'चित्लॉग',
        contactPerson: 'राजन बलामी',
        contactNo: '९८४११४८८३५',
    },
    {
        branchNo: '४९',
        branchName: 'खोकना',
        contactPerson: 'बुद्दभक्त महर्जन',
        contactNo: '९८४९०७६३१४',
    },
    {
        branchNo: '५०',
        branchName: 'दाप्चा नमोबुद्द',
        contactPerson: 'बाबुरत्न महर्जन',
        contactNo: '९८४९८५८८२५',
    },
    {
        branchNo: '५१',
        branchName: 'नस्वॉ मिसा पुच',
        contactPerson: 'मिनेश्वरी श्रेष्ठ',
        contactNo: '९८४१५०४८६३',
    },
    {
        branchNo: '५२',
        branchName: 'ईन्द्र सरोवरमार्खु',
        contactPerson: 'ज्वाहारीलाल बलामी',
        contactNo: '',
    },
    {
        branchNo: '५३',
        branchName: 'गोकर्णेश्वर',
        contactPerson: 'स्याम श्रेष्ठ',
        contactNo: '',
    },
    {
        branchNo: '५४',
        branchName: 'ओख',
        contactPerson: 'अन्जन प्रधान',
        contactNo: '९८५५०७२१२०',
    },
    {
        branchNo: '५५',
        branchName: 'सिभिल होम्स तीनथाना',
        contactPerson: 'स्याम न्हुछे प्रधान',
        contactNo: '९८४१४१४८२४',
    },
    {
        branchNo: '५६',
        branchName: 'काभ्रे नाला',
        contactPerson: 'रामेश्वर कर्माचार्य',
        contactNo: '९८४१३६५३७५',
    },
    {
        branchNo: '५७',
        branchName: 'च्यामासीं ख्वप',
        contactPerson: 'पूर्ण दुवाल',
        contactNo: '९८५१०८७५५६',
    },
    {
        branchNo: '५८',
        branchName: 'मकवानपुर यें स्वापु',
        contactPerson: 'नारायण नकर्मी',
        contactNo: '',
    },
    {
        branchNo: '५९',
        branchName: 'ख्वर लायकु',
        contactPerson: 'दिललक्ष्मी ताम्राकार',
        contactNo: '९८४१४७७१०४',
    },
    {
        branchNo: '६०',
        branchName: 'पलॉती',
        contactPerson: 'रामकृष्ण श्रेष्ठ',
        contactNo: '९८४१४८५८६०',
    },
    {
        branchNo: '६१',
        branchName: 'काभ्रे मिसा भोंत',
        contactPerson: 'बिनु कर्माचार्य',
        contactNo: '९८४१३७८३९०',
    },
    {
        branchNo: '६२',
        branchName: 'मरिण सिन्धुली',
        contactPerson: 'धन बहादुर श्रेष्ठ',
        contactNo: '९८६१८२५३०८',
    },
    {
        branchNo: '६३',
        branchName: 'पलॉती मिसा',
        contactPerson: 'सुवर्ण मैया कर्माचार्य',
        contactNo: '९८०३६२४१०५',
    },
    {
        branchNo: '६४',
        branchName: 'सलिंचा मिसा',
        contactPerson: 'अनु शायमी',
        contactNo: '',
    },
    {
        branchNo: '६५',
        branchName: 'स्कूल पुच',
        contactPerson: 'सुष्मा श्रेष्ठ',
        contactNo: '९८६००३५२१७',
    },
    {
        branchNo: '६६',
        branchName: 'तिलोत्तमा मयजू खल',
        contactPerson: 'मीलन श्रेष्ठ मीनु',
        contactNo: '९८४७५९०११८',
    },
    {
        branchNo: '६७',
        branchName: 'झी पासापुच',
        contactPerson: 'सुमन शायमी',
        contactNo: '९८५१०६३०००',
    },
    {
        branchNo: '६८',
        branchName: 'कालिमाटी पुच',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '६९',
        branchName: ' टिकरे कचा',
        contactPerson: 'जयकृष्ण महर्जन',
        contactNo: '९८५१०१३५५६',
    },
    {
        branchNo: '७०',
        branchName: 'म्हेखु धार्डिंग',
        contactPerson: 'सन्तोष श्रेष्ठ',
        contactNo: '९८४००५५८१७',
    },
    {
        branchNo: '७१',
        branchName: 'धापासी',
        contactPerson: 'नन्दलाल श्रेष्ठ',
        contactNo: '',
    },
    {
        branchNo: '७२',
        branchName: 'सिकाली खोना',
        contactPerson: 'पद्म शोभा',
        contactNo: '',
    },
    {
        branchNo: '७३',
        branchName: 'फ्लोरीडा अमेरीका',
        contactPerson: 'दिकेन्द्र मास्के',
        contactNo: '',
    },
    {
        branchNo: '७४',
        branchName: 'अमल्सी किर्तिपुर',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '७५',
        branchName: 'सामाखुशी',
        contactPerson: 'केशव भक्त प्रधानॉग',
        contactNo: '९८५१०२४९३५',
    },
    {
        branchNo: '७६',
        branchName: 'सालनदी मिसा',
        contactPerson: 'ज्ञान श्री श्रेष्ठ',
        contactNo: '९८४१९६०९०६',
    },
    {
        branchNo: '७७',
        branchName: ' एकजती बज्रयेगिनी',
        contactPerson: 'चन्दा श्रेष्ठ',
        contactNo: '९८४९५०६६८४',
    },
    {
        branchNo: '७८',
        branchName: 'थलि',
        contactPerson: 'शान्ती बिसंखे',
        contactNo: '',
    },
    {
        branchNo: '७९',
        branchName: 'अनुसन्धान',
        contactPerson: 'तुल्सीलाल सिं',
        contactNo: '',
    },
    {
        branchNo: '८०',
        branchName: 'पलखुशी मिसा',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '८१',
        branchName: 'अष्ट्रेलिया',
        contactPerson: 'अष्टरत्न बुद्दाचार्य',
        contactNo: '',
    },
    {
        branchNo: '८२',
        branchName: 'चम्पापुर तख्यो',
        contactPerson: 'मंगलाल गोपाली',
        contactNo: '९८११२२५१२३',
    },
    {
        branchNo: '८३',
        branchName: 'स्थुली',
        contactPerson: 'अमर बहादुर डंगोल',
        contactNo: '९८४९४१२९२६',
    },
    {
        branchNo: '८४',
        branchName: 'कोरियन कचा',
        contactPerson: 'लक्ष्मण सुन्दर सैजु',
        contactNo: '',
    },
    {
        branchNo: '८५',
        branchName: 'न्यूजील्याण्ड कचा',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '८६',
        branchName: 'डेनमार्क कचा',
        contactPerson: 'सिनिया सेन ताम्राकार',
        contactNo: '',
    },
    {
        branchNo: '८७',
        branchName: 'बल्खु',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '८८',
        branchName: 'यल बालकुमारी',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '८९',
        branchName: 'न्ह्योखा',
        contactPerson: 'बिमला मानन्धर',
        contactNo: '९८४१३२८०७५',
    },
    {
        branchNo: '९०',
        branchName: 'दाप्चा क्वाथ',
        contactPerson: 'हरेराम श्रेष्ठ',
        contactNo: '९८४९१८७७८१',
    },
    {
        branchNo: '९१',
        branchName: 'टुसाल सुब्बागाउ',
        contactPerson: 'नवराज श्रेष्ठ',
        contactNo: '९८४१३८४७८६',
    },
    {
        branchNo: '९२',
        branchName: 'यालाछें पलॉती',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '९३',
        branchName: 'लाछी तेतु',
        contactPerson: 'स्वस्तिका महर्जन',
        contactNo: '९८४९३९८२६९',
    },
    {
        branchNo: '९४',
        branchName: 'जियालाचिटी',
        contactPerson: 'कमला श्रेष्ठ',
        contactNo: '९८४१६६८९९६',
    },
    {
        branchNo: '९५',
        branchName: 'रुस कचा',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '९६',
        branchName: 'पोर्चुगल कचा',
        contactPerson: 'ज्योती तुलाधर',
        contactNo: '',
    },
    {
        branchNo: '९७',
        branchName: 'फर्पिंग',
        contactPerson: 'छोरीमैयॉ श्रेष्ठ',
        contactNo: '९८१३६६७९७९',
    },
    {
        branchNo: '९८',
        branchName: 'साखा मिसा',
        contactPerson: 'मुनु भैल श्रेष्ठ',
        contactNo: '९८४९१२७४६५',
    },
    {
        branchNo: '९९',
        branchName: 'यल पाटन',
        contactPerson: 'रीना शाक्य',
        contactNo: '९८६४६८७६५६',
    },
    {
        branchNo: '१००',
        branchName: 'थकाली',
        contactPerson: 'प्रकाशमान श्रेष्ठ',
        contactNo: '९८४१४९६२३५',
    },
    {
        branchNo: '१०१',
        branchName: 'लालबन्दी',
        contactPerson: 'बिक्रम प्रकाश हाडा',
        contactNo: '९८४४०३३४९४',
    },
    {
        branchNo: '१०२',
        branchName: 'आन्तरीक पर्यटन',
        contactPerson: 'राधेस्याम महर्जन',
        contactNo: '',
    },
    {
        branchNo: '१०३',
        branchName: 'ईट्टे संखु',
        contactPerson: 'स्यामकृष्ण श्रेष्ठ',
        contactNo: '९८६०६५४३९८',
    },
    {
        branchNo: '१०४',
        branchName: 'लण्डन पलॉती',
        contactPerson: 'कृष्णहरी मास्के',
        contactNo: '',
    },
    {
        branchNo: '१०५',
        branchName: 'त्वापचा मिसा',
        contactPerson: 'लीला डंगोल',
        contactNo: '९८४८३०८७५१',
    },
    {
        branchNo: '१०६',
        branchName: 'मू ध्वाखा मिसा',
        contactPerson: 'पवित्रा डंगोल',
        contactNo: '९८६१३१८७९४',
    },
    {
        branchNo: '१०७',
        branchName: 'सक्वमी रक्स',
        contactPerson: 'सुजन मल्ल',
        contactNo: '९८६०००१४९२',
    },
    {
        branchNo: '१०८',
        branchName: 'हेटौंडा',
        contactPerson: '',
        contactNo: '',
    },
    {
        branchNo: '१०९',
        branchName: 'बनस्पती अनुसन्धान',
        contactPerson: 'डा धर्म डंगोल',
        contactNo: '९८५५०५८६९१',
    },
    {
        branchNo: '११०',
        branchName: 'चण्डेश्वरी मिसा',
        contactPerson: 'मनिता श्रेष्ठ',
        contactNo: '९८४१५१४०४५',
    },
    {
        branchNo: '१११',
        branchName: 'लिधंसा',
        contactPerson: 'रामकृष्ण महर्जन',
        contactNo: '',
    },
    {
        branchNo: '११२',
        branchName: 'काभ्रे कचा भोंत',
        contactPerson: 'गणेश रंजित',
        contactNo: '९८५१०६४१२४',
    },
];
