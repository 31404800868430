import React from 'react'
import Gallery1 from '../images/gallery-img1.png'
import '../css/Notice.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Autoplay } from 'swiper'
import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Img1 from '../images/Gallery/image11.jpg'
import Img2 from '../images/Gallery/image6.jpg'
import Img3 from '../images/Gallery/image9.jpg'
import Img4 from '../images/Gallery/image7.jpg'

const Gallery = () => {

    const galleryList = [
        {
            galleryID: "1",
            AlbumName: "Untitled Album",
            AlbumImage: Img1,
        },
        {
            galleryID: "2",
            AlbumName: "Album 2",
            AlbumImage: Img2,
        },
        {
            galleryID: "3",
            AlbumName: "Album 3",
            AlbumImage: Img3,
        },
        {
            galleryID: "4",
            AlbumName: "Album 4",
            AlbumImage: Img4,
        },
    ]

    return (

        <>

            <div className="notice1 text-center">
                <h3>Gallery</h3>
                <span></span><img src={Gallery1} alt="Gallery" /><span></span>
            </div>
            <div className="notice-body">
                <Swiper
                    slidesPerView={3}
                    loop={false}
                    grabCursor={true}
                    pagination={{ clickable: true, dynamicBullets: true }}
                    navigation={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1
                        },
                        670: {
                            slidesPerView: 2
                        },
                        950: {
                            slidesPerView: 3
                        },
                        1250: {
                            slidesPerView: 3
                        }
                    }}
                    modules={[Pagination, Navigation, Autoplay]}
                >
                    {galleryList.map((item, index) => {
                        return (
                            <SwiperSlide key={item.galleryID}>
                                <div className="notice-slide">
                                    <div className="notice-title text-center">
                                        <h3>{item.AlbumName}</h3>
                                    </div><hr />
                                    <div className="gallery-img">
                                        <img src={item.AlbumImage} alt="Album" />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
            <div className="notice-button">
                <button onClick={() => alert("Gallery Button Clicked !")}>View More Galleries</button>
            </div>

        </>

    )
}

export default Gallery
