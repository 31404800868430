export const contactBoardList = [
    {
        id: "1",
        name: "Binod Maharjan",
        nName: "विनोद महर्जन",
        contact: "9851159446",
        nContact: "९८५११५९४४६",
        post: "Member",
        nPost: "मु नाय"
    },
    {
        id: "2",
        name: "Suresh Saiju",
        contact: "9851168850",
        post: "Member",
        nName: 'सुरेश सैंजु',
        nContact: '९८५११६८८५०',
        nPost: 'मु न्वकु',
    },
    {
        id: "3",
        name: "Amar Kaji Shrestha",
        contact: "9851062374",
        post: "Member",
        nName: 'अमर काजी श्रेष्ठ',
        nContact: '९८५१०६२३७४',
        nPost: 'मु छ्यान्जे',
    },
    {
        id: "4",
        name: "Satya Narayan Shrestha",
        contact: "9851094531",
        post: "Member",
        nName: 'सत्य नारायण श्रेष्ठ',
        nContact: '९८५१०९४५३१',
        nPost: 'ल्यू छ्यान्जे',
    },
    {
        id: "5",
        name: "Mina Gopali",
        contact: "9849020829",
        post: "Member",
        nName: 'मीना गोपाली',
        nContact: '९८४९०२०८२९',
        nPost: 'दॉभरी',
    },
    {
        id: "6",
        name: "Sabita Nakarmi",
        contact: "9840452820",
        post: "Member",
        nName: 'सविता नकर्मी',
        nContact: '९८४०४५२८२०',
        nPost: 'ल्यू दॉभरी',
    },
    {
        id: "7",
        name: "Bhawani Pradhan",
        contact: "9845070654",
        post: "Member",
        nName: 'भवानी प्रधान',
        nContact: '९८४५०७०६५४',
        nPost: 'मु दुज',
    },
    {
        id: "8",
        name: "Gopal Krishna Nakarmi",
        contact: "9849479555",
        post: "Member",
        nName: 'गोपाल कृष्ण नकर्मी',
        nContact: '९८४९४७९५५५',
        nPost: 'मु दुज',
    },
    {
        id: "9",
        name: "Subarna Manandhar",
        contact: "9851083228",
        post: "Member",
        nName: 'सुवर्ण मानन्धर',
        nContact: '९८५१०८३२२८',
        nPost: 'मु दुज',
    },
    {
        id: "10",
        name: "Hari Maharjan",
        contact: "9849186183",
        post: "Member",
        nName: 'हरि महर्जन',
        nContact: '९८४९१८६१८३',
        nPost: 'मु दुज',
    },
    {
        id: "11",
        name: "Saral Gopal Maharjan",
        contact: "9843074641",
        post: "Member",
        nName: 'सरल गोपाल महर्जन',
        nContact: '९८४३०७४६४१',
        nPost: 'मु दुज',
    },
    {
        id: "12",
        name: "Dev Shrestha",
        contact: "9844366477",
        post: "Member",
        nName: 'देव श्रेष्ठ',
        nContact: '९८४४३६६४७७',
        nPost: 'मु दुज',
    },
    {
        id: "13",
        name: "Surya Laxmi Maharjan",
        contact: "9843177239",
        post: "Member",
        nName: 'सुर्यलक्ष्मी महर्जन',
        nContact: '९८४३१७७२३९',
        nPost: 'मु दुज',
    },
    {
        id: "14",
        name: "Umesh Prakash Shrestha",
        contact: "9851076855",
        post: "Member",
        nName: 'उमेश प्रकाश श्रेष्ठ',
        nContact: '९८५१०७६८५५',
        nPost: 'मु दुज',
    },
    {
        id: "15",
        name: "Raju Shrestha",
        contact: "9857077045",
        post: "Member",
        nName: 'राजु श्रेष्ठ',
        nContact: '९८५७०७७०४५',
        nPost: 'मु दुज',
    },
    {
        id: "16",
        name: "Gauri Shankar Shrestha",
        contact: "9849419629",
        post: "Member",
        nName: 'गौरी शंकर श्रेष्ठ',
        nContact: '९८४९४१९६२९',
        nPost: 'मु दुज',
    },
    {
        id: "17",
        name: "Juju Man Shrestha",
        contact: "9841501438",
        post: "Member",
        nName: 'जुजुमान श्रेष्ठ',
        nContact: '९८४१५०१४३८',
        nPost: 'नि वर्तमान मु नाय',
    },
    {
        id: "18",
        name: "Shanta Prakash Shrestha",
        contact: "9851021785",
        post: "Member",
        nName: 'शान्त प्रकाश श्रेष्ठ',
        nContact: '९८५१०२१७८५',
        nPost: 'सम्रसक',
    },
];

export const kawakajiList = [
    {
        id: '1',
        name: '',
        contact: '',
        post: '',
        nName: 'सुरेश सैंजु',
        nContact: '९८५११६८८५०',
        nPost: 'मु कव कजि',
    },
    {
        id: '2',
        name: '',
        contact: '',
        post: '',
        nName: 'श्री कृष्ण महर्जन',
        nContact: '९८४१७५२५८३',
        nPost: 'नेवा सांस्कृतिक स्वायत्त क्षेत्र',
    },
    {
        id: '3',
        name: '',
        contact: '',
        post: '',
        nName: 'कृष्णबहादुर श्रेष्ठ',
        nContact: '९८५११३९९४५',
        nPost: 'कृषि कव कजि',
    },
    {
        id: '4',
        name: '',
        contact: '',
        post: '',
        nName: 'डा. रसिला अमात्य',
        nContact: '९८५१००४८२१',
        nPost: 'स्वास्थ्य कव',
    },
    {
        id: '5',
        name: '',
        contact: '',
        post: '',
        nName: 'जुजुभाई महर्जन',
        nContact: '९८५१०६७४९५',
        nPost: 'शिक्षा कव',
    },
    {
        id: '6',
        name: '',
        contact: '',
        post: '',
        nName: 'तीर्थ स्याउला',
        nContact: '९८४१२९६३३३',
        nPost: 'भाषा लिपी',
    },
    {
        id: '7',
        name: '',
        contact: '',
        post: '',
        nName: 'कृष्णप्रसाद प्रजापति',
        nContact: '९८१८३४५५४४',
        nPost: 'संस्कृति',
    },
    {
        id: '8',
        name: '',
        contact: '',
        post: '',
        nName: 'योगी स्यास्य श्रेष्ठ',
        nContact: '९८०३४६७३९६',
        nPost: 'लमी कव',
    },
    {
        id: '9',
        name: '',
        contact: '',
        post: '',
        nName: 'धर्म नारायण महर्जन',
        nContact: '९८६०३७०१६८',
        nPost: 'सम्पदा कव',
    },
    {
        id: '10',
        name: '',
        contact: '',
        post: '',
        nName: 'सरल गोपाल महर्जन',
        nContact: '९८४३०७४६४१',
        nPost: 'ल्यायम्ह कव',
    },
    {
        id: '11',
        name: '',
        contact: '',
        post: '',
        nName: 'राम प्रसाद श्रेष्ठ',
        nContact: '९८५१०६३९०८',
        nPost: 'सुचं संचार',
    },
    {
        id: '12',
        name: '',
        contact: '',
        post: '',
        nName: 'सुरेन्द्र श्रेष्ठ',
        nContact: '९८५११३४६६१',
        nPost: 'सांगीतिक',
    },
    {
        id: '13',
        name: '',
        contact: '',
        post: '',
        nName: 'निमेषमान श्रेष्ठ',
        nContact: '९८४१७०७०९०',
        nPost: 'व्यापार नेता',
    },
    {
        id: '14',
        name: '',
        contact: '',
        post: '',
        nName: 'बिध्या श्रेष्ठ',
        nContact: '९८४१३१६७८९',
        nPost: 'मयजु कव',
    },
    {
        id: '15',
        name: '',
        contact: '',
        post: '',
        nName: 'अस्तेन्द्र महर्जन',
        nContact: '९८०३४१०५३६',
        nPost: 'सहलह व अनुसङ्घन',
    },
    {
        id: '16',
        name: '',
        contact: '',
        post: '',
        nName: 'विश्वमान श्रेष्ठ',
        nContact: '९८४१६०११७५',
        nPost: 'मृत्यु व दाहसंस्कार',
    },
    {
        id: '17',
        name: '',
        contact: '',
        post: '',
        nName: 'रमेश जोशी',
        nContact: '९८४९१३३८१५',
        nPost: 'अन्तर्राष्ट्रिय स्वपु कव',
    },
    {
        id: '18',
        name: '',
        contact: '',
        post: '',
        nName: 'डा. शान्तवीर सिन तुलाधर',
        nContact: '९८०८८६७०१७ / ४४७९१२१०१६६९',
        nPost: 'दुर्गालाल हनेज्या',
    },
];
