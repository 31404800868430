import React, { useEffect, useState } from 'react'
import { branchList } from './BranchesList'
import '../css/Branches.css'
import BranchLogo from '../images/branches-img.png'
import Topbar from './Topbar'
import Navbar from './Navbar'
import Footer from './Footer'
import axios from 'axios'

const Branches = () => {

    document.title = `हलिं नेवा: गुथि | कचा`

    // const [branchList, setBranchList] = useState([])

    // useEffect(() => {
    //     fetchBranches()
    // }, [])

    // const fetchBranches = () => {
    //     axios.get(`${process.env.REACT_APP_BASEURL}/branches`)
    //         .then(res => {
    //             if (res.data.status === "Success") {
    //                 setBranchList(res.data.branchDetail)
    //             }
    //             else {
    //                 console.log("Error Getting Data From Backend!!")
    //             }
    //         })
    // }

    return (

        <>

            <div className="header-top">
                <Topbar />
            </div>

            <div className="header-navbar">
                <Navbar />
            </div>

            <div className='branches-head'>
                <img src={BranchLogo} alt="Branch" />
                <h1> / कचा व नायपिं</h1>
            </div>
            <div className="branches-body">
                <div className="card-container">
                    {branchList.map((item, index) => {
                        return (
                            <div className="card" key={index}>
                                <div className="card1">
                                    <h3>कचा</h3>
                                    <h3>{item.branchNo}</h3>
                                </div>
                                <div className="card2">
                                    <h4><i className='bi bi-geo-alt-fill' /> &nbsp;{item.branchName}</h4>
                                    <h5><i className='bi bi-person-circle' /> &nbsp;{item.contactPerson}</h5>
                                    <h6><i className='bi bi-telephone-fill' /> &nbsp;{item.contactNo}</h6>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="footer-links">
                <Footer />
            </div>

        </>

    )
}

export default Branches
