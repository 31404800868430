import React, { useState } from 'react'
import '../css/ActivitiesMain.css'
import ActivitiesImg from '../images/activities-img.png'
import ActivitiesModal from './ActivitiesModal'
import { activitiesList } from './ActivitiesList'
import DefaultImg from '../images/Activities-Image/default.jpg'
import Topbar from './Topbar'
import Navbar from './Navbar'
import Footer from './Footer'

const Pagination = ({ itemsPerPage, ActData }) => {

    const [currentPage, setCurrentPage] = useState(1)

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = ActData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(ActData.length / itemsPerPage)

    const handlePageClick = (page) => {
        setCurrentPage(page)
    }

    return (
        <>
            <div className="activities-card-container">
                {currentItems.map((item) => {
                    return (
                        <div className="activities-card" key={item.actID}>
                            <div className="activities-card-img" key={item.actID}>
                                {
                                    item.actImg1 === "" ?
                                        <img src={DefaultImg} alt='default' /> : <img src={item.actImg1} alt='activities-1' />
                                }
                                {/* <img src={item.actImg1} alt="activities-1" /> */}
                            </div>
                            <div className="activities-card-date">
                                <h4>{item.actDetail1}</h4>
                            </div>
                            <div className="activities-card-name">
                                <h3>{item.actDetail2}</h3>
                            </div>
                            <div className="activities-card-btn">
                                <ActivitiesModal ViewActivity={item} />
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageClick(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </>
    )

}

const ActivitiesMain = () => {

    document.title = `हलिं नेवा: गुथि | ज्याझ्वः`
    const sortedActivities = activitiesList.sort((a, b) => b.actID - a.actID)

    const itemsPerPage = 6

    return (

        <>

            <div className="header-top">
                <Topbar />
            </div>

            <div className="header-navbar">
                <Navbar />
            </div>

            <div className='activities-head'>
                <img src={ActivitiesImg} alt="activities" />
                <h1> / ज्याझ्वः</h1>
            </div>

            <div className="activities-body">

                <Pagination itemsPerPage={itemsPerPage} ActData={sortedActivities} />

                {/* <ActivitiesModal />
                <div className="activities-card-container">
                    {currentItems.map((item, index) => {
                        return (
                            <div className="activities-card" key={item.actID}>
                                <div className="activities-card-img" key={item.actID}>
                                    {
                                        item.actImg1 === "" ?
                                            <img src={DefaultImg} alt='default' /> : <img src={item.actImg1} alt='activities-1' />
                                    }
                    <img src={item.actImg1} alt="activities-1" />
                    </div>
                                <div className="activities-card-date">
                                    <h4>{item.actDetail1}</h4>
                                </div>
                                <div className="activities-card-name">
                                    <h3>{item.actDetail2}</h3>
                                </div>
                                <div className="activities-card-btn">
                                    <ActivitiesModal ViewActivity={item} />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="activities-pagination">

                </div> */}

            </div>

            <div className="footer-links">
                <Footer />
            </div>

        </>

    )
}

export default ActivitiesMain
