import React from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'

const DeleteBranch = (props) => {

    const delBranchID = props.DelID
    const delBranchName = props.DelName

    const handleBranchDelete = () => {
        Swal.fire({
            title: 'Are you sure ?',
            text: `You want to delete branchNo = ${delBranchID} & branchName = ${delBranchName}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'red',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_BASEURL}/branches/${delBranchID}`)
                    .then(res => {
                        if (res.data.status === "Success") {
                            Swal.fire(
                                'Deleted!',
                                'Branch Deleted!',
                                'success'
                            )
                        }
                        else {
                            Swal.fire('Error!', '', 'error')
                        }
                    })
            }
        })
    }

    return (

        <>

            <div className="delete-icon">
                <i className="bi bi-trash3 btn-sm" onClick={handleBranchDelete} />
            </div>

        </>

    )
}

export default DeleteBranch
