import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import '../../../css/AdminModal.css'
import { Form, Modal, Row, Col } from 'react-bootstrap'
import Swal from 'sweetalert2'
import axios from 'axios'

const EditProducts = (props) => {

    const [showModal, setShowModal] = useState(false)

    const editImage1 = props.EditProduct.image

    const validateProductSchema = Yup.object().shape({
        name: Yup.string()
            .required('Product Name Is Required !'),
        detail1: Yup.string()
            .required('Detail Is Required !'),
        detail2: Yup.string()
            .required('Detail Is Required !'),
    })

    return (

        <>

            <div className="edit-icon">
                <i className="bi bi-pencil-square btn-sm" onClick={() => setShowModal(true)} />
            </div>

            <div className="activity modal">
                <Modal show={showModal} onHide={() => setShowModal(false)} size='lg' centered>
                    <Modal.Header style={{ background: '#2DA92D' }}>
                        <Modal.Title>
                            <div className="modal-title">
                                <h3>Edit Product</h3>
                            </div>
                        </Modal.Title>
                        <button className='modal-close-btn1' onClick={() => setShowModal(false)}>
                            <i className='bi bi-x cross1' />
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "#f2f2f2" }}>
                        <div className="body-activity">
                            <Formik
                                initialValues={{ name: props.EditProduct.name, detail1: props.EditProduct.detail1, detail2: props.EditProduct.detail2, detail3: props.EditProduct.detail3, detail4: props.EditProduct.detail4 }}
                                validationSchema={validateProductSchema}
                                onSubmit={(values, { resetForm }) => {
                                    Swal.fire({
                                        title: 'Do you want to update Product?',
                                        showDenyButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: 'Update',
                                        denyButtonText: `Don't Update`,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            axios.put(`${process.env.REACT_APP_BASEURL}/products/${props.EditProduct.productID}`, values)
                                                .then(res => {
                                                    if (res.data.status === "Success") {
                                                        Swal.fire('Updated!', '', 'success')
                                                    }
                                                    else {
                                                        Swal.fire('Error!', '', 'error')
                                                    }
                                                })
                                        }
                                        else if (result.isDenied) {
                                            Swal.fire('Unable to update', '', 'error')
                                        }
                                    }).then(setShowModal(false))
                                }}
                            >
                                {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='3' className='label3'>PRODUCT NAME :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='name' type='text' name='name' placeholder='Enter Product Name *' onChange={handleChange} defaultValue={values.name} style={{ borderColor: touched.name && errors.name ? 'red' : '#2DA92D' }} />
                                                {touched.name && errors.name ? (
                                                    <Col className="error-message">{errors.name}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='3' className='label3'>DETAIL 1 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail1' type='text' name='detail1' placeholder='Enter Detail *' onChange={handleChange} defaultValue={values.detail1} style={{ borderColor: touched.detail1 && errors.detail1 ? 'red' : '#2DA92D' }} />
                                                {touched.detail1 && errors.detail1 ? (
                                                    <Col className="error-message">{errors.detail1}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='3' className='label3'>DETAIL 2 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail2' type='text' name='detail2' placeholder='Enter Detail *' onChange={handleChange} defaultValue={values.detail2} style={{ borderColor: touched.detail2 && errors.detail2 ? 'red' : '#2DA92D' }} />
                                                {touched.detail2 && errors.detail2 ? (
                                                    <Col className="error-message">{errors.detail2}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='3' className='label3'>DETAIL 3 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail3' type='text' name='detail3' placeholder='Enter Detail' onChange={handleChange} defaultValue={values.detail3} style={{ borderColor: touched.detail3 && errors.detail3 ? 'red' : '#2DA92D' }} />
                                                {touched.detail3 && errors.detail3 ? (
                                                    <Col className="error-message">{errors.detail3}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='3' className='label3'>DETAIL 4 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail4' type='text' name='detail4' placeholder='Enter Detail' onChange={handleChange} defaultValue={values.detail4} style={{ borderColor: touched.detail4 && errors.detail4 ? 'red' : '#2DA92D' }} />
                                                {touched.detail4 && errors.detail4 ? (
                                                    <Col className="error-message">{errors.detail4}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <div className="activity-img-form">
                                            <div className="activity-img1">
                                                <Form.Label className='label3'>IMAGE :</Form.Label>
                                                {/* <Col sm='3'>
                                                    <input ref={imageRef} type='file' accept='image/*' className='upload-box' onChange={(event) => {
                                                        setFieldValue("image", event.target.files[0].name)
                                                        setImg(event.target.files[0])
                                                    }}
                                                    />
                                                </Col> */}
                                                {editImage1 ? <img src={`http://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/uploads/products/${editImage1}`} alt='' style={{ width: '250px', height: '250px', objectFit: 'contain' }} /> : ""}
                                                {/* {img && <PreviewImage file={img} />} */}
                                            </div>
                                        </div>
                                        <Modal.Footer className='modal-footer'>
                                            <button type='submit' >SAVE</button>
                                            {/* <button onClick={() => setShowModal(false)}>CANCEL</button> */}
                                        </Modal.Footer>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        </>

    )
}

export default EditProducts
