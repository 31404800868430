import Book from '../images/Products/jhigu-book.jpg'
import Coin from '../images/Products/coin.jpg'
import Agarbatti from '../images/Products/agarbatti.jpg'
import Wallet from '../images/Products/wallet.jpg'
import Cap from '../images/Products/cap.jpg'

export const productList = [
    {
        productID: '1',
        image: Book,
        name: 'जिगु हलिं म्ये मुना सफु',
        detail1: 'च्वमी : सुरेश सैजु',
        detail2: 'पिकाक : हलिं नेवा: गुथि, सॉस्कृतिक कव यें',
        detail3: 'मू : २०० तका ।',
        detail4: 'म्ये : सछि व छपु म्ये मुना ।',
    },
    {
        productID: '2',
        image: Coin,
        name: 'वहया सिक्का',
        detail1: 'ने सं ११४३/ बि सं २०८०',
        detail2: 'पिकाक : हलिं नेवा: गुथि, कृषि कव',
        detail3: 'मू : १७०० तक्का ।',
        detail4: '',
    },
    {
        productID: '3',
        image: Agarbatti,
        name: 'महासत्व व पॉचाल नगर अगरवत्ती',
        detail1: 'पिकाक: हलिं नेवा: गुथि, पलॉती कचा',
        detail2: 'मू : ९० व १०० तक्का ।',
        detail3: '',
        detail4: '',
    },
    {
        productID: '4',
        image: Wallet,
        name: 'वालेट',
        detail1: 'पिकाक : हलिं नेवा: गुथि पलॉती कचा',
        detail2: 'मू : १७५ तक्का',
        detail3: '',
        detail4: '',
    },
    {
        productID: '5',
        image: Cap,
        name: 'गुथि क्याप',
        detail1: 'पिकाक : हलिं नेवा: गुथि पलॉती कचा',
        detail2: 'मू : ३५० तक्का ।',
        detail3: 'मू : २०० तका ।',
        detail4: '',
    },
];
