import React, { useEffect, useState } from 'react'
import '../css/NoticePop.css'
import axios from 'axios'
import { noticeList } from './NoticeList'

const NoticePop = (props) => {

    // const [noticeList, setNoticeList] = useState([])

    // useEffect(() => {
    //     fetchNotice()
    // }, [])

    // const fetchNotice = () => {
    //     axios.get(`${process.env.REACT_APP_BASEURL}/notice`)
    //         .then(res => {
    //             if (res.data.status === "Success") {
    //                 setNoticeList(res.data.noticeDetail)
    //             }
    //             else {
    //                 console.log("Error Getting Data From Backend!!")
    //             }
    //         })
    // }

    const activeNotice = noticeList.filter((nItem) => {
        return nItem.nStatus === 'Enable'
    })

    // console.log(activeNotice)

    return (props.trigger) ? (

        <>

            <div className="notice-container">
                <div className="notice">
                    <button className='close-btn' onClick={() => props.setTrigger(false)}>
                        <i className='bi bi-x cross-icon' />
                    </button>
                    <h2>Notice ( सुचं ) !! </h2>
                    {
                        activeNotice.length > 0 ? activeNotice.map((item, index) => {
                            return (
                                <p key={index}>
                                    {item.message} <br />
                                    <b>{item.detail1}</b><br />
                                    <b>{item.detail2}</b><br />
                                    <b>{item.detail3}</b><br />
                                    <b>{item.detail4}</b><br />
                                    <b>{item.detail5}</b><br />
                                    <b>{item.detail6}</b><br />
                                    <b>{item.detail7}</b><br />
                                    <b>{item.detail8}</b><br />
                                    <b>{item.detail9}</b><br />
                                    <b>{item.detail10}</b><br />
                                </p>
                            )
                        }) : props.setTrigger(false)
                    }
                </div>
            </div>

        </>

    ) : "";
}

export default NoticePop
