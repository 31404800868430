import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import '../css/ActivitiesModal.css'

const ActivitiesModal = (props) => {

    const [showModal, setShowModal] = useState(false)

    const viewTitle = props.ViewActivity.actDetail2
    // const viewDate = props.ViewActivity.actDetail1
    const viewImg1 = props.ViewActivity.actImg1
    const viewImg2 = props.ViewActivity.actImg2
    const viewImg3 = props.ViewActivity.actImg3
    const viewDetail1 = props.ViewActivity.actDetail3
    const viewDetail2 = props.ViewActivity.actDetail4
    const viewDetail3 = props.ViewActivity.actDetail5
    const viewDetail4 = props.ViewActivity.actDetail6
    const viewDetail5 = props.ViewActivity.actDetail7
    const viewDetail6 = props.ViewActivity.actDetail8
    const viewDetail7 = props.ViewActivity.actDetail9
    const viewDetail8 = props.ViewActivity.actDetail10
    const viewDetail9 = props.ViewActivity.actDetail11
    const viewDetail10 = props.ViewActivity.actDetail12

    // console.log(viewImg3)

    return (

        <>

            <div className="activities-show-btn">
                <button onClick={() => setShowModal(true)}>View More</button>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} size='lg'>
                <Modal.Header style={{ background: '#2DA92D' }}>

                    <Modal.Title>
                        <div className="activities-header">
                            <h3>{viewTitle}</h3>
                        </div>
                    </Modal.Title>
                    <button className='modal-close-btn' onClick={() => setShowModal(false)}>
                        <i className='bi bi-x cross-icon1' />
                    </button>

                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#f2f2f2" }}>

                    <div className="activities-body-content">
                        {/* <p>{viewDate}</p> */}
                        <p>{viewDetail1}</p>
                        <p>{viewDetail2}</p>
                        <p>{viewDetail3}</p>
                        <p>{viewDetail4}</p>
                        <p>{viewDetail5}</p>
                        <p>{viewDetail6}</p>
                        <p>{viewDetail7}</p>
                        <p>{viewDetail8}</p>
                        <p>{viewDetail9}</p>
                        <p>{viewDetail10}</p>
                    </div>
                    <div className="activities-body-img">
                        <img src={viewImg1} alt="" />
                        <img src={viewImg2} alt="" />
                        <img src={viewImg3} alt="" />
                    </div>

                </Modal.Body>
            </Modal>

        </>

    )
}

export default ActivitiesModal
