import React from 'react'
import Logo from '../../images/logo.png'
import '../../css/Topbar.css'
import Title1 from '../../images/topbar-title.png'
import { NavDropdown, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const AdminTop = () => {

    const displayName = localStorage.getItem('username')

    const navigate = useNavigate()

    const logout = () => {
        axios.get(`${process.env.REACT_APP_BASEURL}/logout`)
            .then(res => {
                if (res.data.status === "Success") {
                    navigate('/login')
                }
                else {
                    alert("Something went wrong!")
                }
            })
    }

    return (

        <>

            <div className="topbar">
                <div className="logo">
                    <img src={Logo} alt="Logo" />
                </div>
                <div className="name-section">
                    {/* <div className="name">World Newa Guthi</div><hr />
                    <div className="name-2">हलिं नेवाः गुथि</div> */}
                    <img src={Title1} alt="Title" />
                </div>
                <div className="left-user">
                    {/* <Navbar.Collapse id="responsive-navbar-nav"> */}

                    {/* <Nav className=""> */}

                    <NavDropdown title={displayName} className='nav-title'>

                        <NavDropdown.Item>Profile</NavDropdown.Item>
                        <NavDropdown.Item>Change Password</NavDropdown.Item>
                        <NavDropdown.Item as={Button} onClick={logout}>Log Out</NavDropdown.Item>

                    </NavDropdown>

                    {/* </Nav> */}

                    {/* </Navbar.Collapse> */}
                </div>
            </div>

        </>

    )
}

export default AdminTop
