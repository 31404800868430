import React from 'react'
import { productList } from './ProductList'
import '../css/Products.css'
import ProductImg from '../images/products-img.png'
import Topbar from './Topbar'
import Navbar from './Navbar'
import Footer from './Footer'

const Products = () => {

    document.title = `हलिं नेवा: गुथि | उत्पादन`

    return (

        <>

            <div className="header-top">
                <Topbar />
            </div>

            <div className="header-navbar">
                <Navbar />
            </div>

            <div className='product-head'>
                <img src={ProductImg} alt="Products" />
                <h1> / हलिं प्रोडक्ट्स</h1>
            </div>
            <div className="product-body">
                <div className="product-card-container">
                    {productList.map((item, index) => {
                        return (
                            <div className="product-card" key={index}>
                                <div className="product-card-img">
                                    <img src={item.image} alt="products" />
                                </div>
                                <div className="product-card-body">
                                    <p>
                                        <h3>{item.name}</h3>
                                        <h4>{item.detail1}</h4>
                                        <h4>{item.detail2}</h4>
                                        <h4>{item.detail3}</h4>
                                        <h4>{item.detail4}</h4>
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="footer-links">
                <Footer />
            </div>

        </>

    )
}

export default Products
