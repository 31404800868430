import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Homepage from './components/Homepage'
import ContactUs from './components/ContactUs'
import Branches from './components/Branches'
import Products from './components/Products'
import Projects from './components/Projects'
import ActivitiesMain from './components/ActivitiesMain'
import Membership from './components/Membership'
import Dashboard from './Admin/pages/Dashboard'
import ActivitiesHome from './Admin/pages/Activities/ActivitiesHome'
import BranchesHome from './Admin/pages/Branches/BranchesHome'
import ProductsHome from './Admin/pages/Products/ProductsHome'
import NoticesHome from './Admin/pages/Notices.js/NoticesHome'
import ProjectsHome from './Admin/pages/Projects/ProjectsHome'
import Login from './components/Login'

const App = () => {

  return (

    <div>

      <Routes>

        <Route exact path='/' element={<Homepage />} />
        <Route path='/aboutus' element={null} />
        <Route path='/activities' element={<ActivitiesMain />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/membership' element={<Membership />} />
        <Route path='/branches' element={<Branches />} />
        <Route path='/products' element={<Products />} />
        <Route path='/gallery' element={null} />
        <Route path='/contactus' element={<ContactUs />} />

        {/* Admin Routes */}

        <Route exact path='/admin/home' element={<Dashboard />} />
        <Route path='/admin/activities' element={<ActivitiesHome />} />
        <Route path='/admin/branches' element={<BranchesHome />} />
        <Route path='/admin/products' element={<ProductsHome />} />
        <Route path='/admin/notice' element={<NoticesHome />} />
        <Route path='/admin/projects' element={<ProjectsHome />} />


        {/* Login Routes */}

        <Route path='/login' element={<Login />} />

        <Route path='*' element={<div className='text-center'><h4>404 Page Not Found !</h4></div>} />

      </Routes>

    </div>

  )
}

export default App
