import React, { useEffect, useState } from 'react'
import AdminTop from '../AdminTop'
import AdminNav from '../AdminNav'
import AdminFooter from '../AdminFooter'
import '../../../css/AdminHome.css'
// import { activitiesList } from '../../../components/ActivitiesList'
import { Table } from 'react-bootstrap'
// import DefaultImg from '../../../images/Activities-Image/default.jpg'
import AddActivities from './AddActivities'
import { useNavigate } from 'react-router-dom'
import EditActivities from './EditActivities'
import DeleteActivity from './DeleteActivity'
import axios from 'axios'

const Pagination = ({ itemsPerPage, Data }) => {

    const [currentPage, setCurrentPage] = useState(1)

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Data.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(Data.length / itemsPerPage)

    const handlePageClick = (page) => {
        setCurrentPage(page)
    }

    return (
        <>

            <Table responsive hover bordered>
                <thead>
                    <tr>
                        <th>S.N.</th>
                        <th>DATE</th>
                        <th>HEADING</th>
                        <th>DETAILS</th>
                        <th>IMAGES</th>
                        <th>OPTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item, index) => {
                        return (
                            <tr key={item.actID}>
                                <td>{index + 1}</td>
                                <td>{item.date}</td>
                                <td>{item.title}</td>
                                <td>
                                    <p>{item.detail1}</p>
                                    <p>{item.detail2}</p>
                                    <p>{item.detail3}</p>
                                    <p>{item.detail4}</p>
                                    <p>{item.detail5}</p>
                                    <p>{item.detail6}</p>
                                    <p>{item.detail7}</p>
                                    <p>{item.detail8}</p>
                                    <p>{item.detail9}</p>
                                    <p>{item.detail10}</p>
                                </td>
                                <td>
                                    <div className="content-img">
                                        <img src={`http://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/uploads/activities/${item.img1}`} alt="" />
                                        <img src={`http://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/uploads/activities/${item.img2}`} alt="" />
                                    </div>
                                </td>
                                <td>
                                    <EditActivities EditItem={item} />
                                    <DeleteActivity DelID={item.actID} DelTitle={item.title} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageClick(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </>
    )

}

const ActivitiesHome = () => {

    const [auth, setAuth] = useState(false)
    const [activitiesList, setActivitiesList] = useState([])

    const navigate = useNavigate()

    axios.defaults.withCredentials = true

    const fetchActivity = () => {
        axios.get(`${process.env.REACT_APP_BASEURL}/activities`)
            .then(res => {
                if (res.data.status === "Success") {
                    setActivitiesList(res.data.activityDetail)
                }
                else {
                    console.log("Error Getting Data From Backend!!")
                }
            })
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}/`)
            .then(res => {
                if (res.data.status === "Success") {
                    // console.log(res.data)
                    setAuth(true)
                }
                else {
                    setAuth(false)
                    alert(res.data.errorMsg)
                    navigate('/login')
                }
            })

        fetchActivity()

    }, [navigate])

    const sortActivities = activitiesList.sort((a, b) => b.actID - a.actID)

    return (

        <>

            {auth ? <>
                <div className="header-top">
                    <AdminTop />
                </div>

                <div className="header-navbar">
                    <AdminNav />
                </div>

                <div className="home-content1">
                    <div className="content-top">
                        <div className="content-top-left">
                            <input type="text" placeholder='Search...' className='content-search' />
                            <i className="bi bi-search" />
                        </div>
                        <div className="content-add">
                            {/* <button className="content-add-button">+ Add Activity</button> */}
                            <AddActivities />
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="content-body-title">
                            <h3>Activities List</h3>
                        </div>
                        <div className="content-body-list">
                            <Pagination itemsPerPage={6} Data={sortActivities} />
                        </div>
                    </div>
                </div>

                <div className="footer-links">
                    <AdminFooter />
                </div>
            </> : ""}

        </>

    )
}

export default ActivitiesHome
