import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Col, Form, Row, Modal } from 'react-bootstrap'
import '../../../css/AdminModal.css'
import Swal from 'sweetalert2'
import axios from 'axios'

const EditBranches = (props) => {

    const [showModal, setShowModal] = useState(false)

    // const phoneRegExp = /^\d{10}$/; // Regular expression to match 10-digit phone number

    const validateBranchSchema = Yup.object().shape({
        branchNo: Yup.string()
            .required('ID Is Required !'),
        branchName: Yup.string()
            .required('Branch Name Is Required !'),
        // contactPerson: Yup.string()
        //     .required('Person Name Required !'),
        // contactNO: Yup.string()
        //     .matches(phoneRegExp, "Invalid Number !")
        //     .required("Number Required !"),
    })

    return (

        <>

            <div className="edit-icon">
                <i className="bi bi-pencil-square btn-sm" onClick={() => setShowModal(true)} />
            </div>

            <div className="activity-modal">
                <Modal show={showModal} onHide={() => setShowModal(false)} size='lg' centered>
                    <Modal.Header style={{ background: '#2DA92D' }}>
                        <Modal.Title>
                            <div className="modal-title">
                                <h3>Edit Branch</h3>
                            </div>
                        </Modal.Title>
                        <button className='modal-close-btn1' onClick={() => setShowModal(false)}>
                            <i className='bi bi-x cross1' />
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "#f2f2f2" }}>
                        <div className="body-activity">
                            <Formik
                                initialValues={{ branchNo: props.EditBranch.branchNo, branchName: props.EditBranch.branchName, contactPerson: props.EditBranch.contactPerson, contactNo: props.EditBranch.contactNo }}
                                validationSchema={validateBranchSchema}
                                onSubmit={(values, { resetForm }) => {
                                    Swal.fire({
                                        title: 'Do you want to update Branch?',
                                        showDenyButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: 'Update',
                                        denyButtonText: `Don't Update`,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            // console.log(props.EditBranch.branchNo, values)
                                            axios.put(`${process.env.REACT_APP_BASEURL}/branches/${props.EditBranch.branchNo}`, values)
                                                .then(res => {
                                                    // console.log(res.data)
                                                    if (res.data.status === "Success") {
                                                        Swal.fire('Updated!', '', 'success')
                                                    }
                                                    else {
                                                        Swal.fire('Error!', '', 'error')
                                                    }
                                                })
                                        }
                                        else if (result.isDenied) {
                                            Swal.fire('Unable to update', '', 'error')
                                        }
                                    }).then(setShowModal(false))
                                }}
                            >
                                {({ values, errors, touched, handleChange, handleSubmit }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='4' className='label2'>BRANCH NO :</Form.Label>
                                            <Col sm='3'>
                                                <Form.Control id='branchNo' type='text' name='branchNo' placeholder='Enter Branch No *' onChange={handleChange} defaultValue={values.branchNo} style={{ borderColor: touched.branchNo && errors.branchNo ? 'red' : '#2DA92D' }} disabled />
                                                {touched.branchNo && errors.branchNo ? (
                                                    <Col className="error-message">{errors.branchNo}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='4' className='label2'>BRANCH NAME :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='branchName' type='text' name='branchName' placeholder='Enter Branch Name *' onChange={handleChange} defaultValue={values.branchName} style={{ borderColor: touched.branchName && errors.branchName ? 'red' : '#2DA92D' }} />
                                                {touched.branchName && errors.branchName ? (
                                                    <Col className="error-message">{errors.branchName}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='4' className='label2'>COORDINATOR NAME :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='contactPerson' type='text' name='contactPerson' placeholder='Enter Coordinator Name *' onChange={handleChange} defaultValue={values.contactPerson} style={{ borderColor: touched.contactPerson && errors.contactPerson ? 'red' : '#2DA92D' }} />
                                                {touched.contactPerson && errors.contactPerson ? (
                                                    <Col className="error-message">{errors.contactPerson}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-3'>
                                            <Form.Label column sm='4' className='label2'>MOBILE NO :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='contactNo' type='text' name='contactNo' placeholder='Enter Mobile No. *' onChange={handleChange} defaultValue={values.contactNo} style={{ borderColor: touched.contactNo && errors.contactNo ? 'red' : '#2DA92D' }} />
                                                {touched.contactNo && errors.contactNo ? (
                                                    <Col className="error-message">{errors.contactNo}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Modal.Footer className='modal-footer'>
                                            <button type='submit'>SAVE</button>
                                            {/* <button onClick={() => setShowModal(false)}>CANCEL</button> */}
                                        </Modal.Footer>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        </>

    )
}

export default EditBranches
