import React from 'react'
import Activity1 from '../images/activities-img1.png'
import '../css/Activities.css'

const Activities = () => {

    const recentList = [
        {
            date: "13",
            month: "JESTHA",
            title: "न्हापांगु परिषद मुंज्या",
            fullDate: "जेष्ठ १३ गते"
        },
        {
            date: "10",
            month: "JESTHA",
            title: "न्हापांगु परिषद मुंज्या",
            fullDate: "जेष्ठ १० गते"
        },
        {
            date: "06",
            month: "JESTHA",
            title: "न्हापांगु परिषद मुंज्या",
            fullDate: "जेष्ठ ६ गते"
        },
        {
            date: "20",
            month: "BAISAKH",
            title: "न्हापांगु परिषद मुंज्या",
            fullDate: "बैशाख २० गते"
        }
    ]

    return (

        <>

            <div className="activities1 text-center">
                <h3>Events</h3>
                <span></span><img src={Activity1} alt="Events" /><span></span>
            </div>
            <div className="activities2 text-center">
                <div className="recent-event">
                    <h3>RECENT EVENTS</h3><hr className='event-hr' />
                    {/* <p>No Recent Events Found ...</p> */}
                    {recentList.map((item, idx) => {
                        return (
                            <>
                                <div className="item-event" key={idx}>
                                    <div className="event-icon">
                                        <i className='bi bi-calendar' />
                                        <span className='date'>{item.date}</span>
                                        <span className="month">{item.month}</span>
                                    </div>
                                    <div className="event-detail">
                                        <h5>{item.title}</h5>
                                        <span>{item.fullDate}</span>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
                <div className="upcoming-event">
                    <h3>UPCOMING EVENT</h3><hr className='event-hr' />
                    <p>No Upcoming Events Found ....</p>
                </div>
            </div>

        </>

    )
}

export default Activities