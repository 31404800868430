import React, { useState } from 'react'
import '../../../css/AdminModal.css'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Modal, Col, Row, Form } from 'react-bootstrap'
import Swal from 'sweetalert2'
import axios from 'axios'

const EditActivities = (props) => {

    const [showModal, setShowModal] = useState(false)

    const editImg1 = props.EditItem.img1
    const editImg2 = props.EditItem.img2

    const validateEditActivitySchema = Yup.object().shape({
        date: Yup.string()
            .required('Date Is Required !'),
        title: Yup.string()
            .required('Title Is Required !'),
        detail1: Yup.string()
            .required('Detail Is Required !')
    })

    return (

        <>

            <div className="edit-icon">
                <i className='bi bi-pencil-square btn-sm' onClick={() => setShowModal(true)} />
            </div>

            <div className="activity-modal">
                <Modal show={showModal} onHide={() => setShowModal(false)} size='xl' centered>
                    <Modal.Header style={{ background: '#2DA92D' }}>
                        <Modal.Title>
                            <div className="modal-title">
                                <h3>Edit Activity</h3>
                            </div>
                        </Modal.Title>
                        <button className='modal-close-btn1' onClick={() => setShowModal(false)}>
                            <i className='bi bi-x cross1' />
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "#f2f2f2" }}>
                        <div className="body-activity">
                            <Formik
                                enableReinitialize
                                initialValues={{ date: props.EditItem.date, title: props.EditItem.title, detail1: props.EditItem.detail1, detail2: props.EditItem.detail2, detail3: props.EditItem.detail3, detail4: props.EditItem.detail4, detail5: props.EditItem.detail5, detail6: props.EditItem.detail6, detail7: props.EditItem.detail7, detail8: props.EditItem.detail8, detail9: props.EditItem.detail9, detail10: props.EditItem.detail10 }}
                                validationSchema={validateEditActivitySchema}
                                onSubmit={(values, { resetForm }) => {
                                    Swal.fire({
                                        title: 'Do you want to Update activity?',
                                        showDenyButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: 'Update',
                                        denyButtonText: `Don't update`,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            axios.put(`${process.env.REACT_APP_BASEURL}/activities/${props.EditItem.actID}`, values)
                                                .then(res => {
                                                    if (res.data.status === "Success") {
                                                        Swal.fire('Updated!', '', 'success')
                                                    }
                                                    else {
                                                        Swal.fire('Error!', '', 'error')
                                                    }
                                                })
                                        }
                                        else if (result.isDenied) {
                                            Swal.fire('Unable to update', '', 'error')
                                        }
                                    }).then(setShowModal(false))
                                }}
                            >
                                {({ values, errors, touched, handleChange, handleSubmit }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DATE :</Form.Label>
                                            <Col sm='3'>
                                                <Form.Control id='date' type='text' name='date' placeholder='Enter Date *' onChange={handleChange} defaultValue={values.date} style={{ borderColor: touched.date && errors.date ? 'red' : '#2DA92D' }} />
                                                {touched.date && errors.date ? (
                                                    <Col className="error-message">{errors.date}</Col>
                                                ) : null}
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>TITLE :</Form.Label>
                                            <Col sm='6'>
                                                <Form.Control id='title' type='text' name='title' placeholder='Enter Title *' onChange={handleChange} defaultValue={values.title} style={{ borderColor: touched.date && errors.date ? 'red' : '#2DA92D' }} />
                                                {touched.title && errors.title ? (
                                                    <Col className="error-message">{errors.title}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 1 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail1' type='text' name='detail1' placeholder='Enter Detail 1 *' onChange={handleChange} defaultValue={values.detail1} style={{ borderColor: touched.detail1 && errors.detail1 ? 'red' : '#2DA92D' }} />
                                                {touched.detail1 && errors.detail1 ? (
                                                    <Col className="error-message">{errors.detail1}</Col>
                                                ) : null}
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 2 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail2' type='text' name='detail2' placeholder='Enter Detail 2' onChange={handleChange} defaultValue={values.detail2} style={{ borderColor: touched.detail2 && errors.detail2 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 3 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail3' type='text' name='detail3' placeholder='Enter Detail 3' onChange={handleChange} defaultValue={values.detail3} style={{ borderColor: touched.detail3 && errors.detail3 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 4 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail4' type='text' name='detail4' placeholder='Enter Detail 4' onChange={handleChange} defaultValue={values.detail4} style={{ borderColor: touched.detail4 && errors.detail4 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 5 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail5' type='text' name='detail5' placeholder='Enter Detail 5' onChange={handleChange} defaultValue={values.detail5} style={{ borderColor: touched.detail5 && errors.detail5 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 6 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail6' type='text' name='detail6' placeholder='Enter Detail 6' onChange={handleChange} defaultValue={values.detail6} style={{ borderColor: touched.detail6 && errors.detail6 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 7 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail7' type='text' name='detail7' placeholder='Enter Detail 7' onChange={handleChange} defaultValue={values.detail7} style={{ borderColor: touched.detail7 && errors.detail7 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 8 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail8' type='text' name='detail8' placeholder='Enter Detail 8' onChange={handleChange} defaultValue={values.detail8} style={{ borderColor: touched.detail8 && errors.detail8 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 9 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail9' type='text' name='detail9' placeholder='Enter Detail 9' onChange={handleChange} defaultValue={values.detail9} style={{ borderColor: touched.detail9 && errors.detail9 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 10 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail10' type='text' name='detail10' placeholder='Enter Detail 10' onChange={handleChange} defaultValue={values.detail10} style={{ borderColor: touched.detail10 && errors.detail10 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <div className="activity-img-form">
                                            <div className="activity-img1">
                                                <Form.Label>IMAGE 1 :</Form.Label>
                                                {/* <Col sm='3'>
                                                    <input ref={imageRef} type='file' name='img1' accept='image/*' className='upload-box' onChange={(event) => {
                                                        setFieldValue("img1", event.target.files[0].name)
                                                        // setImage1(event.target.files[0])
                                                    }}
                                                        disabled
                                                    />
                                                </Col> */}
                                                {editImg1 ? <img src={`http://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/uploads/activities/${editImg1}`} alt="" style={{ width: '250px', height: '250px', objectFit: 'contain' }} /> : ""}
                                                {/* {image1 && <PreviewImage file={image1} />} */}
                                            </div>
                                            <div className="activity-img2">
                                                <Form.Label>IMAGE 2 :</Form.Label>
                                                {/* <Col sm='3'>
                                                    <input ref={imageRef} type='file' name='img2' accept='image/*' className='upload-box' onChange={(event) => {
                                                        setFieldValue("img2", event.target.files[0].name)
                                                        // setImage2(event.target.files[0])
                                                    }}
                                                        disabled
                                                    />
                                                </Col> */}
                                                {editImg2 ? <img src={`http://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/uploads/activities/${editImg2}`} alt="" style={{ width: '250px', height: '250px', objectFit: 'contain' }} /> : ""}
                                                {/* {image2 && <PreviewImage file={image2} />} */}
                                            </div>
                                            {/* <div className="activity-img3">
                                                <Form.Label>IMAGE 3 :</Form.Label>
                                                <Col sm='3'>
                                                    <input ref={imageRef} type='file' accept='image/*' className='upload-box' onChange={(event) => {
                                                        setFieldValue("img3", event.target.files[0].name)
                                                        setImage3(event.target.files[0])
                                                    }}
                                                    />
                                                </Col>
                                                {editImg3 ? <img src={editImg3} alt="" style={{ width: '250px', height: '250px', objectFit: 'contain' }} /> : ""}
                                                {image3 && <PreviewImage file={image3} />}
                                            </div> */}
                                        </div>
                                        <Modal.Footer className='modal-footer'>
                                            <button type='submit'>SAVE</button>
                                            {/* <button onClick={() => setShowModal(false)}>CANCEL</button> */}
                                        </Modal.Footer>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        </>

    )
}

export default EditActivities
