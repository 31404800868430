import React, { useRef, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import '../../../css/AdminModal.css'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PreviewImage from '../PreviewImage'
import Swal from 'sweetalert2'
import axios from 'axios'

const AddActivities = () => {

    const [showModal, setShowModal] = useState(false)
    const [image1, setImage1] = useState(null)
    const [image2, setImage2] = useState(null)

    const imageRef = useRef(null)

    const validateActivitySchema = Yup.object().shape({
        date: Yup.string()
            .required('Date Is Required !'),
        title: Yup.string()
            .required('Title Is Required !'),
        detail1: Yup.string()
            .required('Detail Is Required !'),
        img1: Yup.mixed()
            .required('Image Is Required!'),
        img2: Yup.mixed()
            .required('Image Is Required!')
    })

    return (

        <>

            <div className="rounded btn activity-button" onClick={() => setShowModal(true)}>
                <div className="align-center">
                    <p><i className='bi bi-plus' /> Add Activity</p>
                </div>
            </div>

            <div className="activity-modal">
                <Modal show={showModal} onHide={() => setShowModal(false)} size='xl' centered>
                    <Modal.Header style={{ background: '#2DA92D' }}>
                        <Modal.Title>
                            <div className="modal-title">
                                <h3>Add Activity</h3>
                            </div>
                        </Modal.Title>
                        <button className='modal-close-btn1' onClick={() => setShowModal(false)}>
                            <i className='bi bi-x cross1' />
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "#f2f2f2" }}>
                        <div className="body-activity">
                            <Formik
                                initialValues={{ date: '', title: '', detail1: '', detail2: '', detail3: '', detail4: '', detail5: '', detail6: '', detail7: '', detail8: '', detail9: '', detail10: '', img1: null, img2: null }}
                                validationSchema={validateActivitySchema}
                                onSubmit={(values, { resetForm }) => {
                                    Swal.fire({
                                        title: 'Do you want to add activity?',
                                        showDenyButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: 'Save',
                                        denyButtonText: `Don't Save`,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            const formData = new FormData()
                                            formData.append("date", values.date)
                                            formData.append("title", values.title)
                                            formData.append("detail1", values.detail1)
                                            formData.append("detail2", values.detail2)
                                            formData.append("detail3", values.detail3)
                                            formData.append("detail4", values.detail4)
                                            formData.append("detail5", values.detail5)
                                            formData.append("detail6", values.detail6)
                                            formData.append("detail7", values.detail7)
                                            formData.append("detail8", values.detail8)
                                            formData.append("detail9", values.detail9)
                                            formData.append("detail10", values.detail10)
                                            formData.append("img1", values.img1)
                                            formData.append("img2", values.img2)
                                            axios.post(`${process.env.REACT_APP_BASEURL}/activities`, formData)
                                                .then(res => {
                                                    if (res.data.status === "Success") {
                                                        Swal.fire('Activity Added', '', 'success')
                                                    }
                                                    else {
                                                        Swal.fire(res.data.errorMsg, '', 'error')
                                                    }
                                                })
                                        }
                                        else if (result.isDenied) {
                                            Swal.fire('Unable to add', '', 'error')
                                        }
                                    }).then(resetForm()).then(setImage1(null)).then(setImage2(null))
                                }
                                }
                            >
                                {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DATE :</Form.Label>
                                            <Col sm='3'>
                                                <Form.Control id='date' type='text' name='date' placeholder='Enter Date *' onChange={handleChange} value={values.date} style={{ borderColor: touched.date && errors.date ? 'red' : '#2DA92D' }} />
                                                {touched.date && errors.date ? (
                                                    <Col className="error-message">{errors.date}</Col>
                                                ) : null}
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>TITLE :</Form.Label>
                                            <Col sm='6'>
                                                <Form.Control id='title' type='text' name='title' placeholder='Enter Title *' onChange={handleChange} value={values.title} style={{ borderColor: touched.date && errors.date ? 'red' : '#2DA92D' }} />
                                                {touched.title && errors.title ? (
                                                    <Col className="error-message">{errors.title}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 1 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail1' type='text' name='detail1' placeholder='Enter Detail 1 *' onChange={handleChange} value={values.detail1} style={{ borderColor: touched.detail1 && errors.detail1 ? 'red' : '#2DA92D' }} />
                                                {touched.detail1 && errors.detail1 ? (
                                                    <Col className="error-message">{errors.detail1}</Col>
                                                ) : null}
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 2 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail2' type='text' name='detail2' placeholder='Enter Detail 2' onChange={handleChange} value={values.detail2} style={{ borderColor: touched.detail2 && errors.detail2 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 3 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail3' type='text' name='detail3' placeholder='Enter Detail 3' onChange={handleChange} value={values.detail3} style={{ borderColor: touched.detail3 && errors.detail3 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 4 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail4' type='text' name='detail4' placeholder='Enter Detail 4' onChange={handleChange} value={values.detail4} style={{ borderColor: touched.detail4 && errors.detail4 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 5 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail5' type='text' name='detail5' placeholder='Enter Detail 5' onChange={handleChange} value={values.detail5} style={{ borderColor: touched.detail5 && errors.detail5 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 6 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail6' type='text' name='detail6' placeholder='Enter Detail 6' onChange={handleChange} value={values.detail6} style={{ borderColor: touched.detail6 && errors.detail6 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 7 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail7' type='text' name='detail7' placeholder='Enter Detail 7' onChange={handleChange} value={values.detail7} style={{ borderColor: touched.detail7 && errors.detail7 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 8 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail8' type='text' name='detail8' placeholder='Enter Detail 8' onChange={handleChange} value={values.detail8} style={{ borderColor: touched.detail8 && errors.detail8 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 9 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail9' type='text' name='detail9' placeholder='Enter Detail 9' onChange={handleChange} value={values.detail9} style={{ borderColor: touched.detail9 && errors.detail9 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 10 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail10' type='text' name='detail10' placeholder='Enter Detail 10' onChange={handleChange} value={values.detail10} style={{ borderColor: touched.detail10 && errors.detail10 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <div className="activity-img-form">
                                            <div className="activity-img1">
                                                <Form.Label>IMAGE 1 :</Form.Label>
                                                <Col sm='3'>
                                                    <input ref={imageRef} type='file' name="img1" accept='image/*' className='upload-box' onChange={(event) => {
                                                        setFieldValue("img1", event.target.files[0])
                                                        setImage1(event.target.files[0])
                                                    }}
                                                        style={{ borderColor: touched.img1 && errors.img1 ? 'red' : '#2DA92D' }}
                                                    />
                                                </Col>
                                                {touched.img1 && errors.img1 ? (
                                                    <Col className="error-message">{errors.img1}</Col>
                                                ) : null}
                                                {image1 && <PreviewImage file={image1} />}
                                            </div>
                                            <div className="activity-img2">
                                                <Form.Label>IMAGE 2 :</Form.Label>
                                                <Col sm='3'>
                                                    <input ref={imageRef} type='file' name="img2" accept='image/*' className='upload-box' onChange={(event) => {
                                                        setFieldValue("img2", event.target.files[0])
                                                        setImage2(event.target.files[0])
                                                    }}
                                                        style={{ borderColor: touched.img2 && errors.img2 ? 'red' : '#2DA92D' }}
                                                    />
                                                </Col>
                                                {touched.img2 && errors.img2 ? (
                                                    <Col className="error-message">{errors.img2}</Col>
                                                ) : null}
                                                {image2 && <PreviewImage file={image2} />}
                                            </div>
                                            {/* <div className="activity-img3">
                                                <Form.Label>IMAGE 3 :</Form.Label>
                                                <Col sm='3'>
                                                    <input ref={imageRef} type='file' name="img3" accept='image/*' className='upload-box' onChange={(event) => {
                                                        setFieldValue("img3", event.target.files[0])
                                                        setImage3(event.target.files[0])
                                                    }}
                                                    />
                                                </Col>
                                                {image3 && <PreviewImage file={image3} />}
                                            </div> */}
                                        </div>
                                        <Modal.Footer className='modal-footer'>
                                            <button type='submit'>SAVE</button>
                                            {/* <button onClick={() => setShowModal(false)}>CANCEL</button> */}
                                        </Modal.Footer>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        </>

    )
}

export default AddActivities
