import React from 'react'
import '../css/ContactUs.css'
import { Form, Col, Row } from 'react-bootstrap'
import ContactLogo from '../images/contact-img.png'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { contactBoardList, kawakajiList } from './ContactList'
import Topbar from './Topbar'
import Navbar from './Navbar'
import Footer from './Footer'
import Swal from 'sweetalert2'

const ContactUs = () => {

    document.title = `हलिं नेवा: गुथि | स्वापु`

    const phoneRegExp = /^\d{10}$/; // Regular expression to match 10-digit phone number

    const validateFeedbackSchema = Yup.object().shape({
        name: Yup.string()
            .required("Full Name is Required !!")
            .min(10, "Must have at least 10 Characters !!")
            .max(30, "Must be less than 30 Characters !!"),
        contactno: Yup.string()
            .matches(phoneRegExp, "Invalid Number !!")
            .required("Number Required !!"),
        message: Yup.string()
            .required("Message is Required !!")
            .min(5, "Must have at least 5 Characters !!")
            .max(100, "Must be less than 100 Characters !!"),
        email: Yup.string().email()
            .required("Email is Required !!"),
        subject: Yup.string()
            .required("Subject is Required !!")
            .min(5, "Must have at least 5 Characters !!")
            .max(50, "Must be less than 50 Characters !!"),
    })

    return (

        <>

            <div className="header-top">
                <Topbar />
            </div>

            <div className="header-navbar">
                <Navbar />
            </div>

            <div className='contact-head'>
                {/* <h1>Contact Us</h1> */}
                <img src={ContactLogo} alt="Contact" />
                <h1> / स्वापु तयबहपिं</h1>
            </div>
            <div className='contact-body'>
                <div className='contact-detail'>
                    <h3>Contact Details</h3>
                    <i className='bi bi-telephone-fill' /><span> Call Us</span>
                    <p>
                        {contactBoardList.map((item, index) => {
                            return (
                                <div key={index}>
                                    <li>
                                        {item.nName} : {item.nContact} ( {item.nPost} )
                                    </li>
                                </div>
                            )
                        })}
                    </p>
                    <i className='bi bi-envelope-fill' /><span> Email Us</span>
                    <p>
                        <a href="mailto:wng.culture@gmail.com">wng.culture@gmail.com</a>
                    </p>
                    <i className='bi bi-geo-alt-fill' /><span> Visit Us</span>
                    <div className='location'>
                        <p>मुज्या कुथि, थानकोट, यें</p>
                    </div>
                </div>
                <div className="contact-detail2">
                    <h3>कव कजि तयगु नाँ धल:</h3>
                    <p>
                        {kawakajiList.map((kItem, kIndex) => {
                            return (
                                <div key={kIndex}>
                                    <li>
                                        {kItem.nName} : {kItem.nContact} ( {kItem.nPost} )
                                    </li>
                                </div>
                            )
                        })}
                    </p>
                </div>
            </div>
            <div className="contact-form-main">
                <div className='feedback'>
                    <h3>Send Us Your Feedback</h3><hr />
                    <div className="contact-form">
                        <Formik
                            initialValues={{ name: "", contactno: "", email: "", subject: "", message: "" }}
                            validationSchema={validateFeedbackSchema}
                            onSubmit={
                                (values, { setSubmitting, resetForm }) => {
                                    Swal.fire({
                                        title: 'Are You Sure?',
                                        showDenyButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: 'Send',
                                        denyButtonText: `Don't Send`,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            Swal.fire('Successfully Send!', '', 'success')
                                        }
                                        else if (result.isDenied) {
                                            Swal.fire('Sending Failed!', '', 'error')
                                        }
                                    }).then(console.log(values)).then(setSubmitting(true)).then(resetForm())
                                }
                            }
                        >
                            {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (

                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} className='mb-3' controlId='name'>
                                        <Col sm='10'>
                                            <Form.Control type='text' name='name' placeholder='Enter Full Name *' onChange={handleChange} value={values.name} style={{ borderColor: touched.name && errors.name ? "red" : null }} />
                                            {touched.name && errors.name ? (
                                                <Col className="error-message">{errors.name}</Col>
                                            ) : null}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3' controlId='email'>
                                        <Col sm='10'>
                                            <Form.Control type='text' name='email' placeholder='Enter Your Email *' onChange={handleChange} value={values.email} style={{ borderColor: touched.email && errors.email ? "red" : null }} />
                                            {touched.email && errors.email ? (
                                                <Col className="error-message">{errors.email}</Col>
                                            ) : null}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3' controlId='contactno'>
                                        <Col sm='10'>
                                            <Form.Control type='text' name='contactno' placeholder='Enter Mobile No *' onChange={handleChange} value={values.contactno} style={{ borderColor: touched.contactno && errors.contactno ? "red" : null }} />
                                            {touched.contactno && errors.contactno ? (
                                                <Col className="error-message">{errors.contactno}</Col>
                                            ) : null}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3' controlId='subject'>
                                        <Col sm='10'>
                                            <Form.Control type='text' name='subject' placeholder='Enter Subject *' onChange={handleChange} value={values.subject} style={{ borderColor: touched.subject && errors.subject ? "red" : null }} />
                                            {touched.subject && errors.subject ? (
                                                <Col className="error-message">{errors.subject}</Col>
                                            ) : null}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3' controlId='message' style={{ background: "linear-gradient(to right, transparent, white)" }}>
                                        <Col sm='10'>
                                            <Form.Control as='textarea' name='message' rows={7} placeholder='Enter Your Message . . . *' onChange={handleChange} value={values.message} style={{ borderColor: touched.message && errors.message ? "red" : null }} />
                                            {touched.message && errors.message ? (
                                                <Col className="error-message">{errors.message}</Col>
                                            ) : null}
                                        </Col>
                                    </Form.Group>
                                    <div className="feedback-button">
                                        <button type='submit' disabled={isSubmitting}>Send</button>
                                    </div>
                                </Form>
                            )}

                        </Formik>

                    </div>
                </div>
            </div>

            <div className="footer-links">
                <Footer />
            </div>

        </>

    )
}

export default ContactUs
