import React from 'react'
import ProductIMg from '../images/product-img1.png'
import '../css/Notice.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Autoplay } from 'swiper'
import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { productList } from './ProductList'

const ProductHome = () => {

    return (

        <>

            <div className="notice1 text-center">
                <h3>Products</h3>
                <span></span><img src={ProductIMg} alt="product" /><span></span>
            </div>
            <div className="notice-body">
                <Swiper
                    slidesPerView={3}
                    loop={false}
                    grabCursor={true}
                    pagination={{ clickable: true, dynamicBullets: true }}
                    navigation={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1
                        },
                        670: {
                            slidesPerView: 2
                        },
                        950: {
                            slidesPerView: 3
                        },
                        1250: {
                            slidesPerView: 3
                        }
                    }}
                    modules={[Pagination, Navigation, Autoplay]}
                >
                    {productList.map((item, index) => {
                        return (
                            <SwiperSlide key={item.productID}>
                                <div className="notice-slide">
                                    <div className="notice-title text-center">
                                        <h3>{item.name}</h3>
                                    </div><hr />
                                    <div className="notice-img">
                                        <img src={item.image} alt="products" />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
            <div className="notice-button">
                <button>
                    <a href="/products">View More</a>
                </button>
            </div>

        </>

    )
}

export default ProductHome