import React, { useEffect, useState } from 'react'
import AdminTop from './AdminTop'
import AdminNav from './AdminNav'
import AdminFooter from './AdminFooter'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const Dashboard = () => {

    const [auth, setAuth] = useState(false)

    const navigate = useNavigate()
    axios.defaults.withCredentials = true

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}/`)
            .then(res => {
                if (res.data.status === "Success") {
                    // console.log(res.data)
                    setAuth(true)
                    localStorage.setItem('username', res.data.userDetail)
                }
                else {
                    setAuth(false)
                    alert(res.data.errorMsg)
                    navigate('/login')
                }
            })
    }, [navigate])

    return (

        <>

            {auth ? <>
                <div className="header-top">
                    <AdminTop />
                </div>

                <div className="header-navbar">
                    <AdminNav />
                </div>

                <div className="dashboard">
                    <h3>INTERACTIVE DASHBOARD</h3>
                </div>

                <div className="footer-links">
                    <AdminFooter />
                </div>
            </> : ""}

        </>

    )
}

export default Dashboard
