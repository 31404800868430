import React, { useState, useEffect } from 'react'
import '../css/Homepage.css'
import Topbar from './Topbar'
import Navbar from './Navbar'
// import ImageSlider from './ImageSlider'
import Introduction from './Introduction'
import Activities from './Activities'
import ProductHome from './ProductHome'
import Gallery from './Gallery'
import Footer from './Footer'
import NoticePop from './NoticePop'

const Homepage = () => {

    const [timedPopup, setTimedPopup] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setTimedPopup(true);
        }, 1000);
    }, [])

    return (

        <>

            <div>
                <NoticePop trigger={timedPopup} setTrigger={setTimedPopup} />
            </div>

            <div className='header-top'>
                <Topbar />
            </div>

            <div className='header-navbar'>
                <Navbar />
            </div>

            {/* <div>
                <ImageSlider />
            </div> */}

            <div>
                <Introduction />
            </div>

            <div>
                <Activities />
            </div>

            <div>
                <ProductHome />
            </div>

            <div>
                <Gallery />
            </div>

            <div className='footer-links'>
                <Footer />
            </div>


        </>

    )
}

export default Homepage
