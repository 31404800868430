import React, { useState } from 'react'
import Logo from '../images/logo.png'
import Title1 from '../images/topbar-title.png'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Form, Row, Col } from 'react-bootstrap'
import '../css/Login.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const Login = () => {

    const [showPassword, setShowPassword] = useState(false)

    const navigate = useNavigate()

    axios.defaults.withCredentials = true

    const validateLoginSchema = Yup.object().shape({
        username: Yup.string()
            .required('USERNAME REQUIRED !')
            .max(15, 'USERNAME MUST BE LESS THAN 15 CHARACTER !'),
        password: Yup.string()
            .required('PASSWORD REQUIRED !')
            .min(8, 'MUST BE MORE THAN 8 CHARACTERS !')
    })

    const togglePassword = () => {
        setShowPassword(prevState => !prevState)
    }

    return (

        <>

            <div className="login-container">
                <div className="login-body">
                    <div className="login-left">
                        <img src={Logo} alt="" className='logo-img' />
                        <img src={Title1} alt="" />
                    </div>
                    <div className="login-right">
                        <Formik
                            initialValues={{ username: '', password: '' }}
                            validationSchema={validateLoginSchema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                // console.log(values)
                                setTimeout(() => {

                                    axios.post(`${process.env.REACT_APP_BASEURL}/login`, values)
                                        .then(setSubmitting(true))
                                        .then(resetForm())
                                        .then((res) => {
                                            if (res.data.status === "Success") {
                                                // localStorage.setItem('username', res.data.userDetail)
                                                navigate('/admin/home')
                                            }
                                            else {
                                                alert(res.data.errorMsg)
                                            }
                                        })

                                }, 1000)
                            }}
                        >
                            {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} className='mb-4'>
                                        <h3>द्व। झास</h3>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-4'>
                                        <Form.Label column sm='5'>USERNAME :</Form.Label>
                                        <Col sm='7'>
                                            <Form.Control id='username' type='text' name='username' placeholder='Enter Username *' onChange={handleChange} value={values.username} style={{ borderColor: touched.username && errors.username ? 'red' : '#2DA92D' }} />
                                            {touched.username && errors.username ? (
                                                <Col className="error-message">{errors.username}</Col>
                                            ) : null}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Form.Label column sm='5'>PASSWORD :</Form.Label>
                                        <Col sm='7'>
                                            <Form.Control id='password' type={showPassword ? 'text' : 'password'} name='password' placeholder='Enter Password *' onChange={handleChange} value={values.password} style={{ borderColor: touched.password && errors.password ? 'red' : '#2DA92D' }} />
                                            <div className="rounded btn view-password" onClick={togglePassword}>
                                                {showPassword ? <i className='bi bi-eye-slash-fill' /> : <i className='bi bi-eye-fill' />}
                                            </div>
                                            {touched.password && errors.password ? (
                                                <Col className="error-message" style={{ marginTop: '-1.3em' }}>{errors.password}</Col>
                                            ) : null}
                                        </Col>
                                    </Form.Group>
                                    <div className="login-button">
                                        <button type='submit' disabled={isSubmitting}>SIGN IN</button>
                                        <text>OR</text>
                                        <p>Go Back To <span><a href="/">HOMEPAGE</a></span></p>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Login
