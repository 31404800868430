import React from 'react'
import Logo from '../images/logo.png'
import '../css/Topbar.css'
import Title1 from '../images/topbar-title.png'

const Topbar = () => {

    return (

        <>

            <div className="topbar">
                <div className="logo">
                    <img src={Logo} alt="Logo" />
                </div>
                <div className="name-section">
                    {/* <div className="name">World Newa Guthi</div><hr />
                    <div className="name-2">हलिं नेवाः गुथि</div> */}
                    <img src={Title1} alt="Title" />
                </div>
                <div className="login">
                    <div className="align-center">
                        <a href="/login">द्व। झास</a>
                    </div>
                    {/* <button onClick={()=>alert("Login Clicked !")}>द्व। झास</button> */}
                </div>
            </div>

        </>

    )
}

export default Topbar
