import React, { useState, useEffect } from 'react'
import AdminTop from '../AdminTop'
import AdminNav from '../AdminNav'
import AdminFooter from '../AdminFooter'
import '../../../css/AdminHome.css'
import { Table } from 'react-bootstrap'
// import { productList } from '../../../components/ProductList'
import AddProducts from './AddProducts'
import { useNavigate } from 'react-router-dom'
import EditProducts from './EditProducts'
import DeleteProduct from './DeleteProduct'
import axios from 'axios'

const Pagination = ({ itemsPerPage, Data }) => {

    const [currentPage, setCurrentPage] = useState(1)

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Data.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(Data.length / itemsPerPage)

    const handlePageClick = (page) => {
        setCurrentPage(page)
    }

    return (
        <>

            <Table responsive hover bordered>
                <thead>
                    <tr>
                        <th>PRODUCT ID</th>
                        <th>PRODUCT NAME</th>
                        <th>DETAILS</th>
                        <th>IMAGE</th>
                        <th>OPTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.productID}</td>
                                <td>{item.name}</td>
                                <td>
                                    <p>{item.detail1}</p>
                                    <p>{item.detail2}</p>
                                    <p>{item.detail3}</p>
                                    <p>{item.detail4}</p>
                                </td>
                                <td><img src={`http://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/uploads/products/${item.image}`} alt="" /></td>
                                <td>
                                    {/* <div className="body-edit"> */}
                                    <EditProducts EditProduct={item} />
                                    {/* </div> */}
                                    {/* <div className="body-delete"> */}
                                    <DeleteProduct DelID={item.productID} DelName={item.name} />
                                    {/* </div> */}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageClick(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </>
    )

}

const ProductsHome = () => {

    const [auth, setAuth] = useState(false)
    const [productList, setProductList] = useState([])

    const navigate = useNavigate()
    axios.defaults.withCredentials = true

    const fetchProducts = () => {
        axios.get(`${process.env.REACT_APP_BASEURL}/products`)
            .then(res => {
                if (res.data.status === "Success") {
                    setProductList(res.data.productDetail)
                }
                else {
                    console.log("Error Getting Data From Backend!!")
                }
            })
    }

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_BASEURL}/`)
            .then(res => {
                if (res.data.status === "Success") {
                    // console.log(res.data)
                    setAuth(true)
                }
                else {
                    setAuth(false)
                    alert(res.data.errorMsg)
                    navigate('/login')
                }
            })

        fetchProducts()

    }, [navigate])

    return (

        <>

            {auth ? <>
                <div className="header-top">
                    <AdminTop />
                </div>

                <div className="header-navbar">
                    <AdminNav />
                </div>

                <div className="home-content1">
                    <div className="content-top">
                        <div className="content-top-left">
                            <input type="text" placeholder='Search...' className='content-search' />
                            <i className="bi bi-search" />
                        </div>
                        <div className="content-add">
                            {/* <button className="content-add-button">+ Add Product</button> */}
                            <AddProducts />
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="content-body-title">
                            <h3>Product List</h3>
                        </div>
                        <div className="content-body-list">
                            <Pagination itemsPerPage={10} Data={productList} />
                        </div>
                    </div>
                </div>

                <div className="footer-links">
                    <AdminFooter />
                </div>
            </> : ""}

        </>

    )
}

export default ProductsHome
