import React from 'react'
import '../css/Membership.css'
import MembershipLogo from '../images/membership-img.png'
import DownloadForm from '../downloads/form.pdf'
import Topbar from './Topbar'
import Navbar from './Navbar'
import Footer from './Footer'

const Membership = () => {

    const handleDownload = () => {
        const pdfUrl = DownloadForm;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'membership.pdf';
        link.click();
    }

    return (

        <>

            <div className="header-top">
                <Topbar />
            </div>

            <div className="header-navbar">
                <Navbar />
            </div>

            <div className="membership-head">
                <img src={MembershipLogo} alt="Membership" />
                <h1>/ सदस्यता</h1>
            </div>
            <div className="membership-content">
                <div className="membership-download">
                    <div className="membership-Form">
                        <p>
                            <h3>Download Form</h3>
                        </p>
                        <button onClick={handleDownload}>Download</button>
                    </div>
                    <div className="membership-modal">
                        <p>
                            <h3>Register Online</h3>
                        </p>
                        <button onClick={() => alert("COMING SOON !!!")}>Register</button>
                    </div>
                </div>
                <div className="membership-list">
                    <h3 className='text-center'>LIST HERE . . . . </h3>
                </div>
            </div>

            <div className="footer-links">
                <Footer />
            </div>

        </>

    )
}

export default Membership
