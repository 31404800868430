import React from 'react'
import '../css/Introduction.css'
import AboutImg from '../images/about-img1.png'
// import IntroImage from '../images/logo.png'

const Introduction = () => {

    return (

        <>

            <div className="content-1">
                <div className="introduction">
                    {/* <h4>Introduction To World Newa Guthi</h4> */}
                    <b><h4>हलिं नेवा: गुथि म्हसीका</h4></b> <hr />
                    <p>हलि नेवा: गुथि दकले न्हापॉ स्वनिग दुने या थि थि नॉ जापिं भाजु मयजु पिं नेपाल सम्वत ११२९ या चिर स्वाईगु अष्टमी या न्ही खुन्हु थक्वातय नेवा त हे जाना हे थ्व हलिं नेवा गुथि पलिस्था जुगु जुल । बिश्वया नेवा: तयत संगठीत याना नेवा तयगु नितिं नेवा: त हे न्ह्या वनेमा धैगु कथं अले आर्थिंक रुपं नं छम्ह नेवा: नं मेम्ह नेवा: यात तिव बिया मंका सम्पन्न यायमा धायगु भावना ज्वना भाषा, संस्कृति व तजिलजि म्वाका तयगु आजु ज्वना नेपालय जक मखु बिश्वया मे मेगु देशय तकनं थ्यंके धुंकगु दु ।</p>
                    <p>थाय थासय जुया हे च्वनी । आ तकया दथुई देशं दुने व पिने याना १३० ल्या ति निस्वने धुंकगु जुल । थ्व हलिं नेवा: गुथिं दँ या दँय पतिकं चिर स्वाईगु अष्टमी खुन्हु छगु दंय दँमुज्या सम्मेलन कथं छगु दँय तँमुज्या अंधिबेशन कथं जुया वईगु जुई व दंमुज्या तंमुज्याय कचा पाखें छम्ह कचा नाय व मेम्ह केन्द्रिय दुजं ब्वती कायगु याई धासा तँमुज्यॉ निदं तक्कया नितिं न्हु ज्यासना पुच ल्येगु याई । धासा अथेतुं कव कजिपिं नं ल्येगु याई । थौं कन्हे झिं न्हेगु कव थुके या आजु पुगंकेत थि थि ज्या याना च्वंगु दु ।</p>
                    <p> <b>कचा निस्वनेत मागु :</b></p>
                    <p>देशय व बिदेशया छुनं थासय कचा गठन याय माल धासा म्होचालय खुम्ह दुजत दयका स्थानिय नामं कचा दयके फईगु ब्यवस्था दु । कचाय छम्ह नीय, छम्ह छ्यान्जे, छम्ह दॉभरी, छम्ह केन्द्रय दुज ( गुम्ह सिनॉ केन्द्रय परिंषद मुंज्याय ब्वती कायगु व चुनावय दनेगु अधिकार दई ) अले मेपिं सकल दुज जुई ।</p>
                    <p><b>कचा या दुज तयगु शुल्क या ब्यवस्था :</b></p>
                    <p>दुज पतिकं ७०० दॉ दुथ्याका दुज जुईगु ब्यवस्था दु । व दुज जुया दुथ्याई ७०० दॉ मध्ये ३५० मृत्यु संस्कार कोषय दुथ्याई, २०० आकस्मीक कोष्य दुथ्याई, १०० दॉ नय मखंपिंन्त नय खंकेगु कोषय दुथ्याई बॉकी ५० दॉ सचिवालय कोषय दुथ्याई ।</p>
                </div>
                {/* <div className="introduction-image">
                    <img src={IntroImage} alt="Introduction" />
                </div> */}
            </div>

            <div className="head-3 text-center">
                <h3>About Us</h3>
                <span></span><img src={AboutImg} alt="About Us" /><span></span>
            </div>

            <div className="content-2">
                <div className="content-body">
                    <h3>कचा तय दुज तयत बिईगु सेवा सुविधा :</h3>
                    <ul>
                        <li>सुं दुजपिं मन्त धासा रु २५००० नापं बिचा हायकेगु ब्यबस्था जुई ।</li>
                        <li>दुज तयत दैवी प्रकोपं व दीर्ध ल्वय लग्जुसा खुल्हा तकया निति विना ब्याज ४०००० तकया ब्यबस्था । खल्हा तकनं मपुलधासा १० प्रतिशत ब्याज या ब्यबस्था जुई ।</li>
                        <li>नय मखंपि सु नेवा: त दत धासा कचॉ शिफारीस जुम्ह सित मनीसिव ठहर् जुसा नकेगु त्वंकेगु ब्यवस्था ।</li>
                        <li>थ्व नापं कचॉ थगु हे कथं लय पतिकं दॉ मुना छुं ब्यवस्था याय फई, थके या नितिं मेगु हे ब्यबस्था यायमा ।</li>
                    </ul>
                </div>
            </div>

        </>

    )
}

export default Introduction
