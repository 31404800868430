import React, { useState, useEffect } from 'react'
import '../css/Navbar.css'

const Navbar = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isNavbarFixed, setIsNavbarFixed] = useState(false);
    const [activeLink, setactiveLink] = useState('');

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setIsNavbarFixed(true);
            } else {
                setIsNavbarFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (

        <>
            <nav className={`navbar ${isMenuOpen ? 'responsive' : ''} ${isNavbarFixed ? 'fixed' : ''}`}>
                <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                    <ul>
                        <li>
                            <a
                                href="/"
                                className={activeLink === 'home' ? 'active' : ''}
                                onClick={() => setactiveLink('home')}>
                                Home<br /><hr /><br /><p>/</p><span className='span'><b>मु पौ</b></span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#aboutus"
                                className={activeLink === 'aboutus' ? 'active' : ''}
                                onClick={() => setactiveLink('aboutus')}>
                                About Us<br /><hr /><br /><p>/</p><span className='span'><b>झिगु खंय्</b></span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/activities"
                                className={activeLink === 'activities' ? 'active' : ''}
                                onClick={() => setactiveLink('activities')}>
                                Activities<br /><hr /><br /><p>/</p><span className='span'><b>ज्याझ्वः</b></span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/projects"
                                className={activeLink === 'projects' ? 'active' : ''}
                                onClick={() => setactiveLink('projects')}>
                                Projects<br /><hr /><br /><p>/</p><span className='span'><b>ग्वसाः</b></span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/membership"
                                className={activeLink === 'membership' ? 'active' : ''}
                                onClick={() => setactiveLink('membership')}>
                                Membership<br /><hr /><br /><p>/</p><span className='span'><b>सदस्यता</b></span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/branches"
                                className={activeLink === 'branches' ? 'active' : ''}
                                onClick={() => setactiveLink('branches')}>
                                Branches<br /><hr /><br /><p>/</p><span className='span'><b>कचा</b></span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/products"
                                className={activeLink === 'products' ? 'active' : ''}
                                onClick={() => setactiveLink('products')}>
                                {/* <p>Products</p><hr /><p>उत्पादन</p> */}
                                Products<br /><hr /><br /><p>/</p><span className='span'><b>उत्पादन</b></span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#gallery"
                                className={activeLink === 'gallery' ? 'active' : ''}
                                onClick={() => setactiveLink('gallery')}>
                                Gallery<br /><hr /><br /><p>/</p><span className='span'><b>धुकु</b></span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/contactus"
                                // activeLink="active"
                                className={activeLink === 'contactus' ? 'active' : ''}
                                onClick={() => setactiveLink('contactus')}>
                                Contact Us<br /><hr /><br /><p>/</p><span className='span'><b>स्वापु</b></span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="menu-toggle" onClick={handleMenuToggle}>
                    <i className={`bi ${isMenuOpen ? 'bi-x-lg' : 'bi-list'}`}></i>
                </div>
            </nav>
        </>

    )
}

export default Navbar
