import React, { useState, useRef } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import '../../../css/AdminModal.css'
import { Form, Modal, Row, Col } from 'react-bootstrap'
import PreviewImage from '../PreviewImage'
import Swal from 'sweetalert2'
import axios from 'axios'

const AddProducts = () => {

    const [showModal, setShowModal] = useState(false)
    const [img, setImg] = useState(null)

    const imageRef = useRef(null)

    const validateProductSchema = Yup.object().shape({
        image: Yup.mixed()
            .required('Image Is Required !'),
        name: Yup.string()
            .required('Product Name Is Required !'),
        detail1: Yup.string()
            .required('Detail Is Required !'),
        detail2: Yup.string()
            .required('Detail Is Required !'),
    })

    return (

        <>

            <div className="rounded btn activity-button" onClick={() => setShowModal(true)}>
                <div className="align-center">
                    <p><i className='bi bi-plus' /> Add Product</p>
                </div>
            </div>

            <div className="activity modal">
                <Modal show={showModal} onHide={() => setShowModal(false)} size='lg' centered>
                    <Modal.Header style={{ background: '#2DA92D' }}>
                        <Modal.Title>
                            <div className="modal-title">
                                <h3>Add Product</h3>
                            </div>
                        </Modal.Title>
                        <button className='modal-close-btn1' onClick={() => setShowModal(false)}>
                            <i className='bi bi-x cross1' />
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "#f2f2f2" }}>
                        <div className="body-activity">
                            <Formik
                                initialValues={{ image: null, name: '', detail1: '', detail2: '', detail3: '', detail4: '' }}
                                validationSchema={validateProductSchema}
                                onSubmit={(values, { resetForm }) => {
                                    Swal.fire({
                                        title: 'Do you want to add Product?',
                                        showDenyButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: 'Save',
                                        denyButtonText: `Don't Save`,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            const formData = new FormData()
                                            formData.append('name', values.name)
                                            formData.append('detail1', values.detail1)
                                            formData.append('detail2', values.detail2)
                                            formData.append('detail3', values.detail3)
                                            formData.append('detail4', values.detail4)
                                            formData.append('image', values.image)
                                            // console.log(formData)
                                            axios.post(`${process.env.REACT_APP_BASEURL}/products`, formData)
                                                .then(res => {
                                                    if (res.data.status === "Success") {
                                                        Swal.fire('Product Added!', '', 'success')
                                                    }
                                                    else {
                                                        Swal.fire('Error!', '', 'error')
                                                    }
                                                })
                                        }
                                        else if (result.isDenied) {
                                            Swal.fire('Unable to add', '', 'error')
                                        }
                                    }).then(resetForm()).then(setImg(null))
                                }}
                            >
                                {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='3' className='label3'>PRODUCT NAME :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='name' type='text' name='name' placeholder='Enter Product Name *' onChange={handleChange} value={values.name} style={{ borderColor: touched.name && errors.name ? 'red' : '#2DA92D' }} />
                                                {touched.name && errors.name ? (
                                                    <Col className="error-message">{errors.name}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='3' className='label3'>DETAIL 1 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail1' type='text' name='detail1' placeholder='Enter Detail *' onChange={handleChange} value={values.detail1} style={{ borderColor: touched.detail1 && errors.detail1 ? 'red' : '#2DA92D' }} />
                                                {touched.detail1 && errors.detail1 ? (
                                                    <Col className="error-message">{errors.detail1}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='3' className='label3'>DETAIL 2 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail2' type='text' name='detail2' placeholder='Enter Detail *' onChange={handleChange} value={values.detail2} style={{ borderColor: touched.detail2 && errors.detail2 ? 'red' : '#2DA92D' }} />
                                                {touched.detail2 && errors.detail2 ? (
                                                    <Col className="error-message">{errors.detail2}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='3' className='label3'>DETAIL 3 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail3' type='text' name='detail3' placeholder='Enter Detail' onChange={handleChange} value={values.detail3} style={{ borderColor: touched.detail3 && errors.detail3 ? 'red' : '#2DA92D' }} />
                                                {touched.detail3 && errors.detail3 ? (
                                                    <Col className="error-message">{errors.detail3}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='3' className='label3'>DETAIL 4 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail4' type='text' name='detail4' placeholder='Enter Detail' onChange={handleChange} value={values.detail4} style={{ borderColor: touched.detail4 && errors.detail4 ? 'red' : '#2DA92D' }} />
                                                {touched.detail4 && errors.detail4 ? (
                                                    <Col className="error-message">{errors.detail4}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <div className="activity-img-form">
                                            <div className="activity-img1">
                                                <Form.Label className='label3'>IMAGE :</Form.Label>
                                                <Col sm='3'>
                                                    <input ref={imageRef} type='file' name='image' accept='image/*' className='upload-box' onChange={(event) => {
                                                        setFieldValue("image", event.target.files[0])
                                                        setImg(event.target.files[0])
                                                    }}
                                                    />
                                                </Col>
                                                {img && <PreviewImage file={img} />}
                                            </div>
                                        </div>
                                        <Modal.Footer className='modal-footer'>
                                            <button type='submit' >SAVE</button>
                                            {/* <button onClick={() => setShowModal(false)}>CANCEL</button> */}
                                        </Modal.Footer>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        </>

    )
}

export default AddProducts
