import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Col, Form, Modal, Row } from 'react-bootstrap';
import '../../../css/AdminModal.css'
import Swal from 'sweetalert2';
import axios from 'axios';

const AddProjects = () => {

    const [showModal, setShowModal] = useState(false)

    const validateProjectSchema = Yup.object().shape({
        projectID: Yup.string()
            .required("ID Is Required !"),
        projectName: Yup.string()
            .required('Project Name Is Required !'),
        // location: Yup.string()
        //     .required('Location Is Required !')
    })

    return (

        <>

            <div className="rounded btn activity-button" onClick={() => setShowModal(true)}>
                <div className="align-center">
                    <p><i className='bi bi-plus' /> Add Project</p>
                </div>
            </div>

            <div className="activity-modal">
                <Modal show={showModal} onHide={() => setShowModal(false)} size='lg' centered>
                    <Modal.Header style={{ background: '#2DA92D' }}>
                        <Modal.Title>
                            <div className="modal-title">
                                <h3>Add Project</h3>
                            </div>
                        </Modal.Title>
                        <button className='modal-close-btn1' onClick={() => setShowModal(false)}>
                            <i className='bi bi-x cross1' />
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "#f2f2f2" }}>
                        <div className="body-activity">
                            <Formik
                                initialValues={{ projectID: '', projectName: '', location: '' }}
                                validationSchema={validateProjectSchema}
                                onSubmit={(values, { resetForm }) => {
                                    Swal.fire({
                                        title: 'Do you want to add Project?',
                                        showDenyButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: 'Save',
                                        denyButtonText: `Don't Save`,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            axios.post(`${process.env.REACT_APP_BASEURL}/projects`, values)
                                                .then(res => {
                                                    if (res.data.status === "Success") {
                                                        Swal.fire('Project Added!', '', 'success')
                                                    }
                                                    else {
                                                        Swal.fire('Error!', '', 'error')
                                                    }
                                                })
                                        }
                                        else if (result.isDenied) {
                                            Swal.fire('Unable to add project', '', 'error')
                                        }
                                    }).then(resetForm())
                                }}
                            >
                                {({ values, errors, touched, handleChange, handleSubmit }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='4' className='label2'>PROJECT ID :</Form.Label>
                                            <Col sm='3'>
                                                <Form.Control id='projectID' type='text' name='projectID' placeholder='Enter Project ID *' onChange={handleChange} value={values.projectID} style={{ borderColor: touched.projectID && errors.projectID ? 'red' : '#2DA92D' }} />
                                                {touched.projectID && errors.projectID ? (
                                                    <Col className="error-message">{errors.projectID}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='4' className='label2'>PROJECT NAME :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='projectName' type='text' name='projectName' placeholder='Enter Project Name *' onChange={handleChange} value={values.projectName} style={{ borderColor: touched.projectName && errors.projectName ? 'red' : '#2DA92D' }} />
                                                {touched.projectName && errors.projectName ? (
                                                    <Col className="error-message">{errors.projectName}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='4' className='label2'>PROJECT LOCATION :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='location' type='text' name='location' placeholder='Enter Project Location' onChange={handleChange} value={values.location} style={{ borderColor: touched.location && errors.location ? 'red' : '#2DA92D' }} />
                                                {touched.location && errors.location ? (
                                                    <Col className="error-message">{errors.location}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Modal.Footer className='modal-footer'>
                                            <button type='submit' >SAVE</button>
                                            {/* <button onClick={() => setShowModal(false)}>CANCEL</button> */}
                                        </Modal.Footer>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        </>

    )
}

export default AddProjects
