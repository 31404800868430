export const projectList = [
    {
        pID: '1',
        projectID: '१',
        projectName: 'हलिं नेवा: अंग्रेजी बोर्डिंग स्कूल',
        location: 'थक्वाय'
    },
    {
        pID: '2',
        projectID: '२',
        projectName: 'नविन बचत तथा ऋण सहकारी संस्था',
        location: 'थानकोट'
    },
    {
        pID: '3',
        projectID: '३',
        projectName: 'डि एन आई सि सि ईन्टरनेट सेवा प्रोजेक्ट',
        location: 'थानकोट'
    },
    {
        pID: '4',
        projectID: '४',
        projectName: 'हलिं एग्रो फार्म',
        location: 'मकवानपुर'
    },
    {
        pID: '5',
        projectID: '५',
        projectName: 'कृषि गोलभेंडा टनिला',
        location: 'ज्यालापाटी, पनौती'
    },
    {
        pID: '6',
        projectID: '६',
        projectName: 'हलिं नेवा: प्रोडक्शन एण्ड सप्लायर्स',
        location: 'थानकोट , यें'
    },
    {
        pID: '7',
        projectID: '७',
        projectName: 'झिगु हलिं इन्भेष्टमेन्ट प्रा लि',
        location: ''
    },
    {
        pID: '8',
        projectID: '८',
        projectName: 'हलिं नेवा पस:',
        location: 'पलांती'
    },
];
