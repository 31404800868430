import React from 'react'
import Logo from '../images/logo.png'
import ImageTitle from '../images/topbar-title.png'
import '../css/Footer.css'
import Facebook from '../images/fb.png'
import Twitter from '../images/twitter.png'
import YT from '../images/yt.png'

const Footer = () => {

    const links = [
        { title: "About Us / झिगु खंय्", url: "#aboutus" },
        { title: "Activities / ज्याझ्वः", url: "/activities" },
        { title: "Branches / कचा", url: "/branches" },
        { title: "Products / उत्पादन", url: "/products" },
        { title: "Gallery / धुकु", url: "#gallery" },
        // { title: "Contact Us", url: "/contactus" },
    ];

    const contacts = [
        { title: 'Email', value: 'wng.culture@gmail.com' },
        { title: 'Phone', value: '०१-४३१२५०५' },
        { title: 'Address', value: 'मुज्या कुथि, थानकोट, यें' },
    ];

    return (

        <>

            <div className='footer'>

                <div className="left">
                    <div className="left-logo">
                        <img src={Logo} alt="Logo" />
                    </div>
                    <div className="left-title">
                        <img src={ImageTitle} alt="World Newa Guthi" />
                    </div>
                </div>

                <div className="center">
                    <ul>
                        <div className="center-head">
                            <h3>Useful Links</h3>
                        </div>
                        <div className="center-links">
                            {links.map((link, index) => (
                                <li key={index}>
                                    <a href={link.url}>{link.title}</a>
                                </li>
                            ))}
                        </div>
                    </ul>
                </div>

                <div className="right">
                    <ul>
                        <div className="right-head">
                            <h3>Contact Us</h3>
                        </div>
                        <div className="right-links">
                            {contacts.map((contact, index) => (
                                <li key={index}>
                                    <span>{contact.title}: </span>
                                    <span>{contact.value}</span>
                                </li>
                            ))}
                        </div>
                    </ul>
                    <div className="social-links">
                        <a href="https://www.facebook.com/profile.php?id=100067596335260" target='_blank' rel='noreferrer'><img src={Facebook} alt="Facebook" /></a>
                        <a href="#twitter" target='_blank' rel='noreferrer'><img src={Twitter} alt="Twitter" /></a>
                        <a href="#youtube" target='_blank' rel='noreferrer'><img src={YT} alt="Youtube" className='yt' /></a>
                    </div>
                </div>

            </div>

            <div className="footer2">
                <h3>&copy; World Newa Guthi Culture Committee. All Rights Reserved. Designed By Oxpan Microsys Pvt. Ltd.</h3>
            </div>

        </>

    )
}

export default Footer
