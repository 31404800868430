import React from 'react'

const AdminFooter = () => {

    return (

        <>

            <div className="footer2">
                <h3>&copy; World Newa Guthi Culture Committee. All Rights Reserved. Designed By Oxpan Microsys Pvt. Ltd.</h3>
            </div>

        </>

    )
}

export default AdminFooter
