import React, { useState, useEffect } from 'react'
import AdminTop from '../AdminTop'
import AdminNav from '../AdminNav'
import AdminFooter from '../AdminFooter'
import '../../../css/AdminHome.css'
import { Table } from 'react-bootstrap'
// import { branchList } from '../../../components/BranchesList'
import AddBranches from './AddBranches'
import { useNavigate } from 'react-router-dom'
import EditBranches from './EditBranches'
import DeleteBranch from './DeleteBranch'
import axios from 'axios'

const Pagination = ({ itemsPerPage, Data }) => {

    const [currentPage, setCurrentPage] = useState(1)

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Data.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(Data.length / itemsPerPage)

    const handlePageClick = (page) => {
        setCurrentPage(page)
    }

    return (
        <>

            <Table responsive hover bordered>
                <thead>
                    <tr>
                        <th>BRANCH NO.</th>
                        <th>BRANCH NAME</th>
                        <th>BRANCH COORDINATOR</th>
                        <th>CONTACT NUMBER</th>
                        <th>OPTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.branchNo}</td>
                                <td>{item.branchName}</td>
                                <td>
                                    {
                                        item.contactPerson === "" ?
                                            <span>N/A</span> : item.contactPerson
                                    }
                                </td>
                                <td>
                                    {
                                        item.contactNo === "" ?
                                            <span>N/A</span> : item.contactNo
                                    }
                                </td>
                                <td>
                                    <EditBranches EditBranch={item} />
                                    <DeleteBranch DelID={item.branchNo} DelName={item.branchName} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageClick(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </>
    )

}

const BranchesHome = () => {

    const [auth, setAuth] = useState(false)
    const [branchList, setBranchList] = useState([])

    const navigate = useNavigate()
    axios.defaults.withCredentials = true

    const fetchBranches = () => {
        axios.get(`${process.env.REACT_APP_BASEURL}/branches`)
            .then(res => {
                if (res.data.status === "Success") {
                    setBranchList(res.data.branchDetail)
                }
                else {
                    console.log("Error Getting Data From Backend!!")
                }
            })
    }

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_BASEURL}/`)
            .then(res => {
                if (res.data.status === "Success") {
                    // console.log(res.data)
                    setAuth(true)
                }
                else {
                    setAuth(false)
                    alert(res.data.errorMsg)
                    navigate('/login')
                }
            })

        fetchBranches()

    }, [navigate])

    return (

        <>

            {auth ? <>
                <div className="header-top">
                    <AdminTop />
                </div>

                <div className="header-navbar">
                    <AdminNav />
                </div>

                <div className="home-content1">
                    <div className="content-top">
                        <div className="content-top-left">
                            <input type="text" placeholder='Search...' className='content-search' />
                            <i className="bi bi-search" />
                        </div>
                        <div className="content-add">
                            {/* <button className="content-add-button">+ Add Branch</button> */}
                            <AddBranches />
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="content-body-title">
                            <h3>Branches List</h3>
                        </div>
                        <div className="content-body-list">
                            <Pagination itemsPerPage={15} Data={branchList} />
                        </div>
                    </div>
                </div>

                <div className="footer-links">
                    <AdminFooter />
                </div>
            </> : ""}

        </>

    )
}

export default BranchesHome
