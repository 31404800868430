import Swal from "sweetalert2"
import axios from 'axios'

const DeleteNotice = (props) => {

    const delNoticeID = props.DelID
    const delNoticeStatus = props.DelStatus

    const handleNoticeDelete = () => {
        delNoticeStatus === 'Enable' ?
            // alert("Notice is active, unable to delete !")
            Swal.fire({
                icon: 'error',
                title: 'Oops....',
                text: 'Notice is active, unable to delete!'
            }) :
            Swal.fire({
                title: 'Are you sure ?',
                text: `You want to delete noticeID = ${delNoticeID}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'red',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`${process.env.REACT_APP_BASEURL}/notice/${delNoticeID}`)
                        .then(res => {
                            if (res.data.status === "Success") {
                                Swal.fire(
                                    'Deleted!',
                                    'Notice table updated!',
                                    'success'
                                )
                            }
                            else {
                                Swal.fire('Error!', '', 'error')
                            }
                        })
                }
            })
    }

    return (

        <>

            <div className="delete-icon">
                <i className="bi bi-trash3 btn-sm" onClick={handleNoticeDelete} />
            </div>

        </>

    )
}

export default DeleteNotice
