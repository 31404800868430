import React, { useState } from 'react'

const PreviewImage = ({ file }) => {

    const [preview, setPreview] = useState(null)

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
        setPreview(reader.result)
    }

    return (

        <>

            {preview ? <img src={preview} alt='' width='150px' /> : "Loading . . "}

        </>

    )
}

export default PreviewImage