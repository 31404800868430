import React from 'react'
import '../css/Projects.css'
import ProjectImg from '../images/projects-img.png'
import Topbar from './Topbar'
import Navbar from './Navbar'
import { projectList } from './ProjectList'
import Footer from './Footer'

const Projects = () => {

    document.title = `हलिं नेवा: गुथि | ग्वसाः`

    return (

        <>

            <div className="header-top">
                <Topbar />
            </div>

            <div className="header-navbar">
                <Navbar />
            </div>

            <div className='project-head'>
                <img src={ProjectImg} alt="Project" />
                <h1> / ग्वसाः</h1>
            </div>

            <div className="project-body">
                <div className="project-body-1">
                    <h3>हलिं प्राजेक्ट्स</h3>
                    <ul>
                        {projectList.map((item, index) => {
                            return (
                                <li key={index}>{item.projectID}. {item.projectName}, {item.location}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>

            <div className="footer-links">
                <Footer />
            </div>

        </>

    )
}

export default Projects
