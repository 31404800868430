import React, { useState } from 'react'
import '../../../css/AdminModal.css'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Form, Col, Row, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import axios from 'axios'

const AddNotice = () => {

    const [showModal, setShowModal] = useState(false)

    const validateNoticeSchema = Yup.object().shape({
        message: Yup.string()
            .required('Message Is Required !'),
        nStatus: Yup.string()
            .required('Status Is Required !')
    })

    return (

        <>

            <div className="rounded btn activity-button" onClick={() => setShowModal(true)}>
                <div className="align-center">
                    <p><i className='bi bi-plus' /> Add Notice</p>
                </div>
            </div>

            <div className="activity-modal">
                <Modal show={showModal} onHide={() => setShowModal(false)} size='xl' centered>
                    <Modal.Header style={{ background: '#2DA92D' }}>
                        <Modal.Title>
                            <div className="modal-title">
                                <h3>Add Notice</h3>
                            </div>
                        </Modal.Title>
                        <button className='modal-close-btn1' onClick={() => setShowModal(false)}>
                            <i className='bi bi-x cross1' />
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "#f2f2f2" }}>
                        <div className="body-activity">
                            <Formik
                                initialValues={{ message: '', nStatus: '', detail1: '', detail2: '', detail3: '', detail4: '', detail5: '', detail6: '', detail7: '', detail8: '', detail9: '', detail10: '' }}
                                validationSchema={validateNoticeSchema}
                                onSubmit={(values, { resetForm }) => {
                                    Swal.fire({
                                        title: 'Do you want to add Notice?',
                                        showDenyButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: 'Save',
                                        denyButtonText: `Don't Save`,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            axios.post(`${process.env.REACT_APP_BASEURL}/notice`, values)
                                                .then(res => {
                                                    if (res.data.status === "Success"){
                                                        Swal.fire('Notice Added!', '', 'success')
                                                    }
                                                    else {
                                                        Swal.fire('Error!', '', 'error')
                                                    }
                                                })
                                            // Swal.fire('Added!', '', 'success')
                                        }
                                        else if (result.isDenied) {
                                            Swal.fire('Unable To Add Notice', '', 'error')
                                        }
                                    }).then(resetForm())
                                }}
                            >
                                {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>STATUS :</Form.Label>
                                            <Col sm='3'>
                                                <Form.Select id='nStatus' type='text' name='nStatus'
                                                    onChange={(event) => setFieldValue('nStatus', event.target.value)}
                                                    value={values.nStatus}
                                                    style={{ borderColor: touched.date && errors.date ? 'red' : '#2DA92D' }}
                                                >
                                                    <option value={null}>Select Status *</option>
                                                    <option value='Enable'>Enable</option>
                                                    <option value='Disable'>Disable</option>
                                                </Form.Select>
                                                {/* <Form.Control id='date' type='date' name='date' placeholder='Enter Date' onChange={handleChange} value={values.date} style={{ borderColor: touched.date && errors.date ? 'red' : '#2DA92D' }} /> */}
                                                {touched.nStatus && errors.nStatus ? (
                                                    <Col className="error-message">{errors.nStatus}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>MESSAGE :</Form.Label>
                                            <Col sm='10'>
                                                <Form.Control as='textarea' rows={6} id='message' name='message' placeholder='Enter Message *' onChange={handleChange} value={values.message} style={{ borderColor: touched.message && errors.message ? 'red' : '#2DA92D' }} />
                                                {touched.message && errors.message ? (
                                                    <Col className="error-message">{errors.message}</Col>
                                                ) : null}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 1 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail1' type='text' name='detail1' placeholder='Enter Detail' onChange={handleChange} value={values.detail1} style={{ borderColor: touched.detail1 && errors.detail1 ? 'red' : '#2DA92D' }} />
                                                {touched.detail1 && errors.detail1 ? (
                                                    <Col className="error-message">{errors.detail1}</Col>
                                                ) : null}
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 2 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail2' type='text' name='detail2' placeholder='Enter Detail' onChange={handleChange} value={values.detail2} style={{ borderColor: touched.detail2 && errors.detail2 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 3 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail3' type='text' name='detail3' placeholder='Enter Detail' onChange={handleChange} value={values.detail3} style={{ borderColor: touched.detail3 && errors.detail3 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 4 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail4' type='text' name='detail4' placeholder='Enter Detail' onChange={handleChange} value={values.detail4} style={{ borderColor: touched.detail4 && errors.detail4 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 5 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail5' type='text' name='detail5' placeholder='Enter Detail' onChange={handleChange} value={values.detail5} style={{ borderColor: touched.detail5 && errors.detail5 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 6 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail6' type='text' name='detail6' placeholder='Enter Detail' onChange={handleChange} value={values.detail6} style={{ borderColor: touched.detail6 && errors.detail6 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-2'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 7 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail7' type='text' name='detail7' placeholder='Enter Detail' onChange={handleChange} value={values.detail7} style={{ borderColor: touched.detail7 && errors.detail7 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 8 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail8' type='text' name='detail8' placeholder='Enter Detail' onChange={handleChange} value={values.detail8} style={{ borderColor: touched.detail8 && errors.detail8 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-3'>
                                            <Form.Label column sm='2' className='label1'>DETAIL 9 :</Form.Label>
                                            <Col sm='4'>
                                                <Form.Control id='detail9' type='text' name='detail9' placeholder='Enter Detail' onChange={handleChange} value={values.detail9} style={{ borderColor: touched.detail9 && errors.detail9 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                            <Form.Label column sm='2' className='label1'>DETAIL 10 :</Form.Label>
                                            <Col sm='5'>
                                                <Form.Control id='detail10' type='text' name='detail10' placeholder='Enter Detail' onChange={handleChange} value={values.detail10} style={{ borderColor: touched.detail10 && errors.detail10 ? 'red' : '#2DA92D' }} />
                                            </Col>
                                        </Form.Group>
                                        <Modal.Footer className='modal-footer'>
                                            <button type='submit'>SAVE</button>
                                            {/* <button onClick={() => setShowModal(false)}>CANCEL</button> */}
                                        </Modal.Footer>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        </>

    )
}

export default AddNotice
